import React, { useEffect, useState } from "react"
import PropertyGrid from "../../components/propertyGrid"
import ViewModeButtons from "../../components/viewModeButtons"
import ProfileActionsButtons from "./profileActionsButtons"
import { Phone } from "../../assets/images/projectsIcons/phone"
import { Home } from "../../assets/images/projectsIcons/home"
import { Date } from "../../assets/images/projectsIcons/date"
import { Email } from "../../assets/images/projectsIcons/email"
import { File } from "../../assets/images/projectsIcons/file"
import defaultImage from "../../assets/images/noData.png"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import placeholder from "../../assets/images/placeholder1.svg"
import { connect } from "react-redux"
import { useInfiniteLoading } from "../../components/hooksComponents/useInfiniteLoading"
import ProfilePropertiesComponent from "./propertiesComponent"
import useMediaQuery from "../../components/hooksComponents/useMediaQuery"
import { property } from "lodash"

const ProfileComponent = ({
  user = {},
  currentUser,
  fetchProperties,
  propertiesTitle,
  loading,
  user: propUser,
}) => {
  const isMyProfile = user?.id === currentUser?.id
  const isMobile = useMediaQuery("(max-width: 671px)")
  const isLaptop = useMediaQuery("(max-width: 1650px)")
  return (
    <div className="greyXLightBg">
      <div className="w-100 row p-5 g-0 myProfileAndTabsBox">
        <div className="col-lg-9 col-12 whiteBg p-lg-4 p-3 myProfileBox">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-4 col-12">
              <div className="my-2 d-flex align-items-center justify-content-center">
                <img
                  style={{ objectFit: "cover" }}
                  src={propUser?.avatar?.url || placeholder}
                  alt="profile"
                  className="br8 myProfileAvatar"
                />
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-md-8 col-12">
              <div className="black px20 my-2 capitalized">
                {isMyProfile ? (
                  <FormattedMessage defaultMessage="My Profile" />
                ) : (
                  user?.role
                )}
              </div>
              <div className="black font-bold my-2 px32">{user.name}</div>
              <div className="darkGrey px20 w-xl-50 line-height-200">
                {user.bio}
              </div>
              <div className="my-2">
                <div className="darkGrey font-bold mb-2 mt-5 px26">
                  <FormattedMessage defaultMessage="More details" />
                </div>
                <div className="my-4 row">
                  <div className="col-lg-4 col-12">
                    {user.phoneNumber && (
                      <div className="darkGrey d-flex px20 mb-3">
                        <Phone />
                        <span className="mx-2">{user.phoneNumber}</span>
                      </div>
                    )}
                    {user.email && (
                      <div className="darkGrey d-flex px20 mb-3">
                        <Email />
                        <span className="mx-2">{user.email}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="darkGrey d-flex px20 mb-3">
                      <Home />
                      <span className="mx-2">Sells 102 properties</span>
                    </div>
                    <div className="darkGrey d-flex px20 mb-3">
                      <File />
                      <span className="mx-2">Rents 107 properties</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="darkGrey d-flex px20 mb-3">
                      <Date color="#616E86" />
                      <span className="mx-2">
                        <FormattedMessage defaultMessage="Joined Simar" />{" "}
                        {moment(user.createdAt).format("DD MMMM YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ minWidth: isLaptop ? null : "22%" }}
          className="col-lg-2 col-12 whiteBg br8 my-5 my-lg-0 myProfileTabs"
        >
          <ProfileActionsButtons user={user} />
        </div>
      </div>

      <div className="px-10rem text-center">
        <ProfilePropertiesComponent
          isMyProfile={isMyProfile}
          isMobile={isMobile}
          userId={user && user.id}
          fetchProperties={fetchProperties}
          title={propertiesTitle}
          loading={loading}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({
  settings: { lang, propertyFilters },
  user: { user },
  shared: { loading },
}) => ({ propertyFilters, lang, loading, currentUser: user })
export default connect(mapStateToProps)(ProfileComponent)
