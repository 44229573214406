import uniqueId from "lodash/uniqueId"
import React, { useContext, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import AssetsUploader from "../../assetsUploader"
import FieldDesc from "../../propertyForm/fieldDesc"
import FormInput, { FormLabel } from "../../propertyForm/formInput"
import ListServiceContext from "../serviceContext"
import Button from "../../button"
import PlusIcon from "../../../assets/icons/plusIcon"
import MinusIcon from "../../../assets/icons/minusIcon"
import PriceListUploader from "../../priceListUploader"
import FormSelect from "../../propertyForm/formSelect"
import ChevRight from "../../../assets/icons/chevRight"
import useMediaQuery from "../../hooksComponents/useMediaQuery"

const ThirdStep = () => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListServiceContext)

  const handleLogoChange = value => {
    setFieldValue("logo", value)
  }
  const isMobile = useMediaQuery("(max-width: 1480px)")
  const handleServiceChange = (e, id) => {
    const newServices = values.service_options?.map?.(s => {
      if (s.id === id) {
        return {
          ...s,
          [e?.target?.name]: e?.target?.value,
        }
      }
      return s
    })

    setFieldValue("service_options", newServices)
  }

  const PopupButton = ({ children, onClick }) => (
    <button
      disabled
      type="button"
      className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
      style={{ border: "1px solid #BFC7D2", color: "#2D3436" }}
    >
      {children}
      <ChevRight style={{ marginRight: "10px" }} />
    </button>
  )

  const addNewService = () => {
    setFieldValue("service_options", [
      ...values.service_options,
      { title: "", price: "", id: uniqueId("service-") },
    ])
  }

  const removeService = id => {
    setFieldValue("service_options", [
      ...values.service_options?.filter?.(s => s.id !== id),
    ])
  }

  useEffect(() => {
    setCurrentStepInputs(["service_options", "logo"])
  }, [setCurrentStepInputs])

  return (
    <div className="res-w-3/4 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Logo & Prices" />
      </FieldDesc>
      <div className="d-flex justify-content-between flex-column flex-lg-row">
        <div style={{ marginRight: "40px" }}>
          <FormLabel>
            <FormattedMessage defaultMessage="Upload your company logo" />
          </FormLabel>
          <AssetsUploader
            files={values.logo}
            setFiles={handleLogoChange}
            type="communityExperts" // from api
          />
          {touched.logo && errors.logo && (
            <div className="error mt-3">
              <FormattedMessage defaultMessage="Please upload the logo before you move on" />
            </div>
          )}
        </div>
        <div className="ms-lg-4 flex-1">
          <div style={{ marginTop: "2px" }}>
            <FormLabel>
              <FormattedMessage defaultMessage="List your services and prices, you can upload an image or a pdf file instead" />
            </FormLabel>
          </div>
          <div className="mt-2">
            {values?.service_options?.map((service, index) => (
              <div style={{ marginLeft: "20px" }}>
                <div
                  key={service.id}
                  className="w-100 d-flex align-items-center space-x-8"
                >
                  <div className="thirdstep3btns"
                  >
                    <FormInput
                      name="title"
                      label={<FormattedMessage defaultMessage="Service" />}
                      value={service.title}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Service Name",
                      })}
                      style={{ width: "231px", height: "48px" }}
                      onChange={e => handleServiceChange(e, service.id)}
                      outerClassName={`mb-0 ${isMobile ? 'mt-2' : 'mt-4'}`}
                      onBlur={handleBlur}
                      error={
                        touched.service_options &&
                        touched.service_options[index] &&
                        errors.service_options &&
                        errors.service_options[index] &&
                        errors.service_options[index]?.title
                      }
                    />
                    <FormInput
                      name="price"
                      label={<FormattedMessage defaultMessage="Price" />}
                      value={service.price}
                      type="number"
                      placeholder={intl.formatMessage({
                        defaultMessage: "Service Price",
                      })}
                      style={{ width: "231px", height: "48px" }}
                      onChange={e => handleServiceChange(e, service.id)}
                      outerClassName={`mb-0 ${isMobile ? 'mt-2' : 'mt-4'}`}
                      onBlur={handleBlur}
                      error={
                        touched.service_options &&
                        touched.service_options[index] &&
                        errors.service_options &&
                        errors.service_options[index] &&
                        errors.service_options[index]?.price
                      }
                    />

                    <div
                      className={`d-flex currencybtnservice ${isMobile ? 'mt-2' : 'mt--5'}`}
                    >
                      <PopupButton onClick={null} children={null}>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "400",
                            lineHeight: "32px",
                            fontSize: '16px'
                          }}
                        >
                          Syrian Pounds SP
                        </div>
                      </PopupButton>
                    </div>
                  </div>
                </div>

                {index > 0 && (
                  <Button
                    className="mt-4 py-4"
                    onClick={() => removeService(service.id)}
                  >
                    <MinusIcon />
                  </Button>
                )}
              </div>
            ))}
            <div
              className="d-flex mt-4 space-x-20 pb-3 align-items-stretch addserviceandcatalogbtns"
            >
              <Button 
              className="addservice"
                color="white"
                onClick={addNewService}
              >
                <FormattedMessage defaultMessage="Add another service" />
                <PlusIcon className="mx-2" />
              </Button>

              <PriceListUploader
                classNames="catalog"
                file={values.priceList}
                setFile={value => setFieldValue("priceList", value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
