import React from "react"
import api from "../_api/profile"
import {
  LOGIN_USER,
  GET_MY_PROPERTIES,
  GET_MY_FAVORITES,
  GET_MY_FOLLOWERS,
  GET_MY_NOTIFICATIONS,
} from "./types"
import { error, success } from "../utils"
import { FormattedMessage } from "react-intl"
import { NotificationManager } from "react-notifications"
import { setLoading } from "./settingsActions"
import { navigate } from "gatsby-link"
import { myProfilePath } from "../components/header"

export const updateMyProfile = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .updateProfile(data)
    .then(res => {
      dispatch({ type: LOGIN_USER, payload: res })
      success(
        <FormattedMessage defaultMessage="Profile updated successfully" />
      )
      navigate(myProfilePath)
    })
    .catch(errors => {
      error()
      // return Promise.reject(errors)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getMyProperties =
  ({ params }) =>
  dispatch => {
    dispatch(setLoading(true))
    return api
      .getMyProperties({ params })
      .then(res => {
        dispatch({ type: GET_MY_PROPERTIES, payload: res.data })
      })
      .catch(() => {
        NotificationManager.error("Oops!, something went wrong", "", 3000)
      })
      .finally(() => dispatch(setLoading(false)))
  }

export const getMyFavorites = () => dispatch => {
  dispatch(setLoading(true))
  return api
    .getMyFavorites()
    .then(res => {
      dispatch({ type: GET_MY_FAVORITES, payload: res.data })
    })
    .catch(() => {
      NotificationManager.error("Oops!, something went wrong", "", 3000)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getMyFollowers = () => dispatch => {
  dispatch(setLoading(true))
  return api
    .getMyFollowers()
    .then(res => {
      dispatch({ type: GET_MY_FOLLOWERS, payload: res.data })
    })
    .catch(() => {
      NotificationManager.error("Oops!, something went wrong", "", 3000)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getMyNotifications = page => dispatch => {
  dispatch(setLoading(true))
  return api
    .getMyNotifications(page)
    .then(res => {
      dispatch({ type: GET_MY_NOTIFICATIONS, payload: res })
      return res
    })
    .catch(() =>
      NotificationManager.error("Oops!, something went wrong", "", 3000)
    )
    .finally(() => dispatch(setLoading(false)))
}
