import React, { useEffect, useRef, useState } from "react"
import AliceCarousel from "react-alice-carousel"
import AssetItem from "./assetItem"
import "react-alice-carousel/lib/alice-carousel.css"
import ChevLeft from "../assets/icons/chevLeft"
import ChevRight from "../assets/icons/chevRight"
import { FormattedMessage } from "react-intl"

const AssetsViewer = ({ assets, mobileHeight = false }) => {
  const [currentViewed, setCurrentViewed] = useState({})
  const ref = useRef(null)

  console.log("rerender")

  useEffect(() => {
    console.log("assets changed")
    console.log(assets)
    const asset = assets?.[0] ? assets[0] : {}
    assets && setCurrentViewed({ ...asset })
  }, [assets])

  const assetsItems = assets?.map?.(a => (
    <div
      key={a.id}
      className="asset-component pointer"
      style={{ width: "177px", height: "117px" }}
      role="button"
      onClick={() => setCurrentViewed({ ...a })}
    >
      <AssetItem key={a.id} url={a.url} type={a.type} thumbnail />
    </div>
  ))

  const onLeftClick = ref => ref.current && (ref.current.scrollLeft -= 250)
  const onRightClick = ref => ref.current && (ref.current.scrollLeft += 250)

  if (assets?.length <= 0)
    return (
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ height: 524, width: 786 }}
      >
        <div className="px20 darkGrey">
          <FormattedMessage defaultMessage="No photos or videos for this property" />
        </div>
      </div>
    )
  return (
    <div className="w-100">
      <div className="w-100" style={{ height: mobileHeight ? 320 : 524, width: 786 }}>
        <AssetItem url={currentViewed?.url} type={currentViewed?.type} />
      </div>
      <div className="property-details-carousel relative my-3">
        <div className="d-flex overflow-auto relative scroll-smooth" ref={ref}>
          {assetsItems}
        </div>

        {/* <button
          aria-label="btn"
          onClick={() => onLeftClick(ref)}
          className={`btn left`}
        >
          <ChevLeft style={{ width: 15 }} />
        </button>
        <button
          aria-label="btn"
          onClick={() => onRightClick(ref)}
          className={`btn right`}
        >
          <ChevRight style={{ width: 15 }} />
        </button> */}
      </div>
    </div>
  )
}

export default AssetsViewer
