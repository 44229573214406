import React from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
import { compose, withProps } from "recompose"
import mapPin from "../assets/images/mapPin.png"

const MapComponentAdd = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA2A_eHw5ge_atyj9UAK2lOsg5EQrH5uc8`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div style={{ height: `131px` }} />
    ),
    mapElement: (
      <div
        style={{
          height: `100%`,
          position: "relative !important",
          borderRadius: "8px",
        }}
      />
    ),
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { lat, long, onClick, zoom, mapTitle, locations, onClickMarker } = props

  return (
    <>
      <GoogleMap
        zoom={zoom}
        center={{ lat: lat, lng: long }}
        onClick={onClick}
        defaultOptions={{
          disableDefaultUI: true, // disable default map UI
          draggable: false, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: false, // allow scale controle
        }}
      >
        {locations.map((location, index) => (
          <Marker
            key={index}
            title={mapTitle}
            position={{
              lat: location.lat,
              lng: location.long,
            }}
            onClick={() => onClickMarker(location)}
            defaultIcon={{
              url: require("../assets/images/mapPin.png"),
            }}
            icon={mapPin}
          />
        ))}
      </GoogleMap>
    </>
  )
})

export default MapComponentAdd
