import React, { useContext, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import FormToggleButton from "../../formToggleButton"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"

export const propertyTypes = [
  {
    value: "villa",
    label: <FormattedMessage defaultMessage="Villa" />,
  },
  { value: "flat", label: <FormattedMessage defaultMessage="Flat" /> },
  { value: "shop", label: <FormattedMessage defaultMessage="Shop" /> },
  { value: "land", label: <FormattedMessage defaultMessage="Land" /> },
  { value: "farm", label: <FormattedMessage defaultMessage="Farm" /> },
  { value: "office", label: <FormattedMessage defaultMessage="Office" /> },
  { value: "warhouse", label: <FormattedMessage defaultMessage="Warhouse" /> },
]
const ThirdStep = ({ propertyTypes }) => {
  const { values, errors, touched, setFieldValue, setCurrentStepInputs } =
    useContext(ListPropertyContext)

  const handleTypeChange = (rel = null) => {
    setFieldValue("type", rel)
  }

  useEffect(() => {
    setCurrentStepInputs(["type"])
  }, [])

  return (
    <div className="res-w-3/4 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Property Type" />
      </FieldDesc>

      {touched.type && errors.type?.id && (
        <span className="error mx-2">
          {errors.type?.id || (typeof errors.type ? errors.type : "")}
        </span>
      )}
      <div className="d-flex flex-wrap justify-content-center"
      style={{paddingBottom: '40px', gap: '15px'}}>
        {propertyTypes?.data?.map?.(type => (
          <FormToggleButton
            className="m-2 m-lg-3"
            key={type.id}
            active={values.type?.id === type.id}
            onClick={() =>
              handleTypeChange({
                id: values.type?.id === type.id ? null : type.id,
              })
            }
          >
            {type.title}
          </FormToggleButton>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = ({ data: { propertyTypes } }) => ({ propertyTypes })
export default connect(mapStateToProps)(ThirdStep)
