import React from "react"
import { FormattedMessage } from "react-intl"
import * as yup from "yup"

const REQUIRED_ERR_MSG = <FormattedMessage defaultMessage="Required" />
const REQUIRED_CATEGORY_MSG = (
  <FormattedMessage defaultMessage="Category is required" />
)
const REQUIRED_AT_LEAST_ONE_MSG = (
  <FormattedMessage defaultMessage="At least one" />
)

const listServiceValidationScheme = yup.object().shape({
  title: yup.string().required(REQUIRED_ERR_MSG),
  first_name: yup.string().required(REQUIRED_ERR_MSG),
  last_name: yup.string().required(REQUIRED_ERR_MSG),
  phone: yup
    .string()
    .required(REQUIRED_ERR_MSG)
    .matches(
      /^[+]]*963]{0,1}[-\s\./0-9].{6,16}$/,
      "Phone number must be a valid syrian number"
    ),
  email: yup
    .string()
    .required(REQUIRED_ERR_MSG)
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Must be a valid email address"
    ),
  category: yup.lazy(value => {
    switch (typeof value) {
      case "object":
        return yup.object().shape({ id: yup.string().required() }).required() // schema for object
      case "string":
        return yup.string().required() // schema for string
      default:
        return yup.string().required() // here you can decide what is the default
    }
  }),
  // category: yup.string().required(REQUIRED_CATEGORY_MSG),
  address: yup.string().required(REQUIRED_ERR_MSG),
  website_link: yup.string().required(REQUIRED_ERR_MSG),
  description: yup.string().required(REQUIRED_ERR_MSG),
  working_hours: yup
    .array(
      yup.object({
        from: yup.string().required(REQUIRED_ERR_MSG),
        to: yup.string().required(REQUIRED_ERR_MSG),
      })
    )
    .min(1),
  service_options: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(REQUIRED_ERR_MSG),
        price: yup.string().required(REQUIRED_ERR_MSG),
      })
    )
    .min(1),
  logo: yup.object().shape({
    id: yup
      .string()
      .required(
        <FormattedMessage defaultMessage="Please upload your assets first before moving on" />
      ),
  }),
  // description: yup.string().required(REQUIRED_ERR_MSG),
  // size: yup
  //   .number()
  //   .required(REQUIRED_ERR_MSG)
  //   .min(1, <FormattedMessage defaultMessage="Must be greater than 0" />),
  // rentCheck: yup
  //   .string()
  //   .oneOf(["month", "year", "sixMonth"])
  //   .required(REQUIRED_ERR_MSG),
  // address: yup.string().required(REQUIRED_ERR_MSG).default("default"),
  // addressDetails: yup.string().required(REQUIRED_ERR_MSG).default("default"),
  // buildingNumber: yup.string().required(REQUIRED_ERR_MSG).default("default"),
  // neighborhood: yup.string().required(REQUIRED_ERR_MSG).default("default"),
  // description: yup.string(),
  // isWithFurniture: yup.bool().required(),
  // isConstructionCompleted: yup.bool().required(),
  // bedRoomsCount: yup.number().required(REQUIRED_ERR_MSG),
  // bathsCount: yup.number().required(REQUIRED_ERR_MSG),
  // unit: yup.string().required(),
  // type: yup
  //   .object({})
  //   .shape({
  //     id: yup.string().required(REQUIRED_ERR_MSG),
  //   })
  //   .required(REQUIRED_ERR_MSG),
  // //   project: {},
  // assets: yup.array().of(
  //   yup.object().shape({
  //     id: yup
  //       .string()
  //       .required(
  //         <FormattedMessage defaultMessage="Please upload your assets first before moving on" />
  //       ),
  //   })
  // ),
  //   amenities: [],
})

export default listServiceValidationScheme
