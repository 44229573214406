import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import { connect, useDispatch } from "react-redux"
import _ from "lodash"
import defaultImage from "../../assets/images/noData.png"
import useForm from "../../components/hooksComponents/useForm"
import { getMyFollowers } from "../../actions/profileActions"
import { agentsCards } from "../../constants/headerItems"
import { FormattedMessage } from "react-intl"
import { navigate } from "@reach/router"
import AgentComponent from "../../components/agentComponent"
import ArrowLeft from "../../assets/icons/arrowLeft"
import { myProfilePath } from "../../components/header"
import PropertyGrid from "../../components/propertyGrid"
import useMediaQuery from "../../components/hooksComponents/useMediaQuery"
import { Grid } from "@material-ui/core"
import dataApi from "../../_api/dataApi"
import { setGeneralModal, setLoading } from "../../actions/settingsActions"
import ShareModal from "../../components/modals/shareModal"

const MyFollowers = props => {
  const { loading, user, myFollowers, properties } = props
  const isMobile = useMediaQuery(`(max-width: 676px)`)
  const [followingList, setFollowingList] = useState([])
  const dispatch = useDispatch()
  const { state, setState } = useForm({})

  useEffect(() => {
    if (user && !loading) {
      setState(user)
    }
  }, [user])

  const fetchFavorites = async () => {
    dispatch(setLoading(true))
    await dataApi.getFollowingList().then(res => {
      if (res && res.data) {
        setFollowingList(res.data)
      }
    })
    dispatch(setLoading(false))
  }
  const shareProfile = id => {
    props.setGeneralModal({
      open: true,
      Component: ShareModal,
      modalClassName: "confirm-modal",
      componentProps: {
        title: "Share Profile",
        pathname: `/app/profile/${id}`,
      },
    })
  }
  useEffect(() => {
    fetchFavorites()
  }, [])

  return (
    <Layout>
      <SEO title="My Favorites" />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="px-10rem text-center myFollowListMain">
          <div className="py-2">
            <div className="signInBorder d-flex align-items-center justify-content-between">
              <span className="font-black black mx-3 myfwstitle">
                <FormattedMessage defaultMessage="My Follow List" />
              </span>
              <button
                className="w-15 justify-content-between d-flex align-items-center primary font-bold button-shadow px-3 py-2 br8 button-primaryBg custom-border-primary gotomyprofilebtn"
                onClick={() => navigate(myProfilePath)}
              >
                <div>
                  <ArrowLeft size="1.5rem" />
                </div>
                <div
                  className="d-lg-flex d-none"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  <FormattedMessage defaultMessage="Go to my profile" />
                </div>
              </button>
            </div>
          </div>
          <div className="row pb-1 px-3 px-md-0">
            <PropertyGrid
              forceVertical={isMobile}
              properties={myFollowers?.data?.slice(0, 6)}
            />
          </div>
          {!_.isEmpty(followingList.filter(item => item && item.id !== null)) ? (
            <div>
              <div className="pb-5">
                <Grid container>
                  {followingList.map(data => {
                    if (data && data.id) {
                      return (
                        <Grid lg={6} xs={12} style={{ padding: 10 }}>
                          <AgentComponent
                            shareProfile={shareProfile}
                            agentData={data}
                            user={user}
                          />
                        </Grid>
                      )
                    }
                  })}
                </Grid>
              </div>
              <div className="h-10vh" />
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center justify-content-center flex-column py-5">
                <img alt={"noData"} src={defaultImage} className="w-sm-100" />
                <div className="my-5">
                  <div className="signInBorder">
                    <div className="text-start font-semi-bold black px36 mx-3">
                      <FormattedMessage defaultMessage="Nothing to show here" />
                    </div>
                    <div className="text-start black px20 mx-3">
                      <FormattedMessage defaultMessage="You dont follow any agents" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-20vh" />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({
  user: { user, myFollowers },
  shared: { loading },
}) => {
  return { user, loading, myFollowers }
}
export default connect(mapStateToProps, { getMyFollowers, setGeneralModal })(
  MyFollowers
)
