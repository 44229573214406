import React, { useContext, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"
import FormCheckbox from "../formCheckbox"
import FormInput from "../formInput"
import FormSelect from "../formSelect"
import CheckboxGroup from "../checkboxGroup"
import ChevRight from "../../../assets/icons/chevRight"
import { Box, Grid } from "@material-ui/core"
import ChevDown from "../../../assets/icons/chevDown"
import useMediaQuery from "../../hooksComponents/useMediaQuery"
import MapComponentAdd from "../../mapComponentAdd"
import { setGeneralModal } from "../../../actions/settingsActions"
import { connect } from "react-redux"
import MarkOnMap from "../../markOnMap"

export const showPhoneOptions = [
  { label: <FormattedMessage defaultMessage="Yes" />, value: true },
  { label: <FormattedMessage defaultMessage="No" />, value: false },
]

const cities = []

const FifthStep = ({ setGeneralModal }) => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListPropertyContext)

  const handleAddressChange = unit => {
    console.log("unit", unit)
    setFieldValue("address", unit.value)
  }
  const changeLocation = location => {
    setFieldValue("lat", location.lat)
    setFieldValue("long", location.long)
  }
  const renderMapMark = () => {
    return (
      <MarkOnMap
        setGeneralModal={setGeneralModal}
        latLng={{ lat: values.lat, long: values.long }}
        changeLocation={changeLocation}
      />
    )
  }
  const openMarkOnMap = () => {
    console.log("test")
    setGeneralModal({
      open: true,
      Component: () => renderMapMark(),
      modalClassName: "signInModal br16 overflow-scroll hideScrollBar",
    })
  }
  const cityOptions = [
    { title: "Homs", value: "Homs" },
    { title: "Deir ez-Zur", value: "Deir ez-Zur" },
    { title: "Ar Raqqah", value: "Ar Raqqah" },
    { title: "Hama", value: "Hama" },
    { title: "Damascus", value: "Damascus" },
    { title: "llion", value: "llion" },
    { title: "Tartus", value: "Tartus" },
    { title: "Al Bab", value: "Al Bab" },
    { title: "Duma", value: "Duma" },
    { title: "Manbij", value: "Manbij" },
    { title: "Dayr Hafir", value: "Dayr Hafir" },
    { title: "Ar Rastan", value: "Ar Rastan" },
    { title: "A'zaz", value: "A'zaz" },
    { title: "Al-Safirah", value: "Al-Safirah" },
    { title: "Darayya", value: "Darayya" },
    { title: "Al Mayadin", value: "Al Mayadin" },
    { title: "Salamiyah", value: "Salamiyah" },
    { title: "Al Tal", value: "Al Tal" },
    { title: "Al Bukamal", value: "Al Bukamal" },
    { title: "Al Tabqah", value: "Al Tabqah" },
    { title: "Jableh", value: "Jableh" },
    { title: "Badh Rajbana", value: "Badh Rajbana" },
    { title: "Khan Shaykhun", value: "Khan Shaykhun" },
    { title: "Al Hasakah", value: "Al Hasakah" },
    { title: "Tall Rifat", value: "Tall Rifat" },
    { title: "Tadif", value: "Tadif" },
    { title: "Nubl", value: "Nubl" },
    { title: "Al-Hawl", value: "Al-Hawl" },
  ]
  const isMobile = useMediaQuery("(max-width: 799px)")
  const PopupButton = ({ children, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
      style={{ border: "1px solid #BFC7D2", color: "#2D3436" }}
    >
      {children}
      <ChevRight style={{ marginRight: "10px" }} />
    </button>
  )
  const SelectButton = ({ children, onClick }) => (
    <button
      type="button"
      onClick={onClick}
      className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
      style={{
        border: "1px solid #BFC7D2",
        color: "#2D3436",
      }}
    >
      {children}
      <ChevDown />
    </button>
  )
  useEffect(() => {
    setCurrentStepInputs([
      "address",
      "addressDetails",
      "buildingNumber",
      "neighborhood",
      "project",
    ])
  }, [])

  return (
    <div className="">
      <FieldDesc>
        <FormattedMessage defaultMessage="Location & Address" />
      </FieldDesc>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid
          spacing={isMobile ? 0 : 3}
          container
          className="hideScrollBar"
          style={{ overflow: "visible !important" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={6}
            style={{ paddingTop: isMobile ? 15 : 12 }}
          >
            <FormSelect
              label={<FormattedMessage defaultMessage="City" />}
              name="address"
              addProperty={true}
              placeholder={intl.formatMessage({
                defaultMessage: "City",
              })}
              outerClassName="w-100 mt-5 mt-lg-0"
              value={{ title: values.address, value: values.address }}
              onChange={e => handleAddressChange(e)}
              onBlur={handleBlur}
              options={cityOptions}
              getOptionLabel={option => option?.title}
              getOptionValue={option => option?.value}
              error={touched.address && errors.address}
            />
            {touched.address && errors.address && (
              <span className="error mx-2 errorCity"></span>
            )}
            {/* <SelectButton onClick={null} children={null}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "32px",
                        fontSize: '16px'
                      }}
                    >
                      Select City
                    </div>
                  </SelectButton> */}
            <FormInput
              tag="textarea"
              name="addressDetails"
              onChange={handleChange}
              value={values.addressDetails}
              onBlur={handleBlur}
              className="w-100"
              placeholder={intl.formatMessage({
                defaultMessage: "Additional Address Details",
              })}
            />
            <div
              className="mx-2 w-100"
              style={{ fontSize: "16px", color: "#616E86" }}
            >
              Use google map to mark the address
            </div>
            <Box className="usegooglemapproperty relative">
              <MapComponentAdd
                lat={values && values.lat ? values.lat : 25.2697}
                long={values && values.long ? values.long : 55.3095}
                zoom={12}
                mapTitle={"MarkOnMap"}
                locations={
                  values && values.lat && values.long
                    ? [{ lat: values.lat, long: values.long }]
                    : []
                }
                onClickMarker={location => console.log(location)}
              />
              <button
                type="button"
                onClick={() => openMarkOnMap()}
                className="markonmap absolute"
                style={{ bottom: 20, left: 20 }}
              >
                <div style={{ marginLeft: "6px", marginBottom: "3px" }}>
                  Mark on map
                </div>
                <ChevRight fill="#FFFFFF" style={{ marginLeft: "12px" }} />
              </button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Box>
              <FormInput
                label={<FormattedMessage defaultMessage="Neighborhood Name" />}
                name="neighborhood"
                type="text"
                onChange={handleChange}
                value={values.neighborhood}
                onBlur={handleBlur}
                error={touched.neighborhood && errors.neighborhood}
                outerClassName="mt-0"
                placeholder={intl.formatMessage({
                  defaultMessage: "EX: Bagdad Street",
                })}
              />
              {touched.neighborhood && errors.neighborhood && (
                <span className="error mx-2 errorNeighborhood"></span>
              )}
              <FormInput
                label={
                  <FormattedMessage defaultMessage="Building Name or Number" />
                }
                onChange={handleChange}
                value={values.buildingNumber}
                onBlur={handleBlur}
                error={touched.buildingNumber && errors.buildingNumber}
                name="buildingNumber"
                type="text"
                placeholder={intl.formatMessage({
                  defaultMessage: "Building Name",
                })}
              />
              {touched.buildingNumber && errors.buildingNumber && (
                <span className="error mx-2 errorBuildingName"></span>
              )}
              <CheckboxGroup
                className="w-100"
                labelClassname={"createProp-no-wrap"}
                label={
                  <FormattedMessage defaultMessage="Is your house part of a project or a housing association" />
                }
                value={values.showPhone}
                options={showPhoneOptions}
                handleChange={() => console.log("test")}
              />
              <FormSelect
                name="project"
                addProperty={true}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select project or housing association",
                })}
                outerClassName="w-100"
                value={values.project}
                onChange={() => console.log("project")}
                onBlur={handleBlur}
                options={[]}
                getOptionLabel={option => option?.title}
                getOptionValue={option => option?.value}
                error={touched.project && errors.project}
              />
              {touched.project && errors.project && (
                <span className="error mx-2"></span>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const mapStateToProps = ({}) => ({})
export default connect(mapStateToProps, {
  setGeneralModal,
})(FifthStep)
