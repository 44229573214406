import { useFormik } from "formik"
import { useState } from "react"
import dataApi from "../../_api/dataApi"
import useStepper from "../hooksComponents/useStepper"
import listPropertyValidationScheme from "./validationScheme"

const initialValues = {
  title: "",
  offerType: "",
  relationship: "",
  price: "",
  size: "",
  sizeUnit: "",
  rentCheck: null,
  address: "",
  lat: null,
  long: null,
  addressDetails: "",
  buildingNumber: "",
  neighborhood: "",
  description: "",
  isWithFurniture: false,
  isConstructionCompleted: false,
  bedRoomsCount: 1,
  bathsCount: 1,
  type: {},
  project: null,
  assets: [],
  amenities: [],
}

function usePropertyForm(handleCreateProperty, steps) {
  const {
    goToNextStep,
    backToPrevStep,
    safeSetStep: setStep,
    CurrentStepComponent,
    step,
  } = useStepper(steps)

  const [currentStepInputs, setCurrentStepInputs] = useState([])

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    submitCount,
    setErrors,
    setTouched,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: listPropertyValidationScheme,
    validateOnBlur: true,
    onSubmit: handleCreateProperty,
  })
  console.log('values',values)

  return {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    submitCount,
    setErrors,
    setTouched,
    dirty,
    //===========
    goToNextStep,
    backToPrevStep,
    setStep,
    CurrentStepComponent,
    step,
    //===========
    currentStepInputs,
    setCurrentStepInputs,
  }
}

export default usePropertyForm
