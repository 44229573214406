import React, { useEffect } from "react"
import { Router, useLocation } from "@reach/router"
import NotFound from "../pages/404"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import MyProfile from "./profilePages/myProfile"
import MyFavorites from "./profilePages/myFavorites"
import MyFollowers from "./profilePages/myFollowers"
import MyNotifications from "./profilePages/myNotifications"
import Projects from "./project"
import PrivateRoute from "../components/routingComponent/privateRoute"
import { getCurrentUser } from "../actions/authActions"
import Properties from "./property"
import Service from "./service"
import AllServices from "./allServices"
import MapFinder from "./mapFinder"
import PropertyForm from "../components/propertyForm"
import PropertyEditForm from "../components/propertyForm/edit"
import ServiceEditForm from "../components/serviceForm/edit"
import ServiceForm from "../components/serviceForm"
import EditProfile from "./profilePages/editProfile"
import Profile from "./profilePages/profile"
import { myProfilePath } from "../components/header"

const IndexPage = props => {
  const { user } = props
  const { pathname } = useLocation()

  useEffect(() => {
    user && props.getCurrentUser(user)
  }, [])

  useEffect(() => {
    if (pathname === "/app" || pathname === "/app/")
      typeof window !== "undefined" && navigate(myProfilePath)
  }, [pathname])

  return (
    <Router basepath="/app">
      <PrivateRoute component={MyProfile} path="/my-profile" />
      <PrivateRoute component={MyFavorites} path="/my-profile/favorites" />
      <PrivateRoute component={MyFollowers} path="/my-profile/followers" />
      <PrivateRoute
        component={MyNotifications}
        path="/my-profile/notifications"
      />
      <PrivateRoute component={EditProfile} path="/my-profile/edit" />
      <PrivateRoute component={PropertyForm} path="/property/create" />
      <PrivateRoute component={PropertyEditForm} path="/property/:id/edit" />
      <PrivateRoute component={ServiceForm} path="/service/create" />
      <PrivateRoute component={ServiceEditForm} path="/service/:id/edit" />
      <Profile path="/profile/:id" />
      <Projects path="/project/:id" />
      <Projects path="/association/:id" />
      <Properties path="/property/:id" />
      <Properties path="/broker/:id" />
      <Projects path="/project/:id" />
      <Projects path="/association/:id" />
      <Service path="/service/:id" />
      <AllServices path="/all-services" />
      <MapFinder path="/map-finder" />
      <NotFound default />
    </Router>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ user })
export default connect(mapStateToProps, {
  getCurrentUser,
})(IndexPage)
