import { CondOperator } from "@nestjsx/crud-request"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { MAX, MIN } from "../filter/priceRange"
import {
  bathsCountOptions,
  bedRoomCountOptions,
} from "../propertyForm/steps/seventhStep"

const equalityFilters = [
  "offerType",
  "isWithFurniture",
  "isConstructionCompleted",
  "relationship",
]

export default function usePropertyFilters() {
  const filters = useSelector(state => state?.settings?.propertyFilters)

  const apiFilters = useMemo(() => {
    if (typeof filters !== "object") {
      return []
    }
    const tempFilters = []

    equalityFilters.forEach(field => {
      if (filters?.hasOwnProperty(field) && filters[field] !== null) {
        tempFilters.push({
          field,
          operator: CondOperator.EQUALS,
          value: filters[field],
        })
      }
    })

    if (filters?.bedRoomsCount !== null) {
      let operator = ""
      if (filters.bedRoomsCount < bedRoomCountOptions.at(-1).value) {
        operator = CondOperator.EQUALS
      } else {
        operator = CondOperator.GREATER_THAN_EQUALS
      }
      tempFilters.push({
        field: "bedRoomsCount",
        operator: operator,
        value: filters.bedRoomsCount,
      })
    }

    if (filters?.bathsCount !== null) {
      let operator = ""
      if (filters.bathsCount < bathsCountOptions.at(-1).value) {
        operator = CondOperator.EQUALS
      } else {
        operator = CondOperator.GREATER_THAN_EQUALS
      }
      tempFilters.push({
        field: "bathsCount",
        operator: operator,
        value: filters.bathsCount,
      })
    }

    if (filters.type?.length > 0) {
      tempFilters.push({
        field: "type.id",
        operator: CondOperator.IN,
        value: filters.type?.join(","),
      })
    }

    if (filters.amenities?.length > 0) {
      tempFilters.push({
        field: "amenities.id",
        operator: CondOperator.IN,
        value: filters.amenities?.join(","),
      })
    }

    if (filters.rentCheck?.length > 0) {
      tempFilters.push({
        field: "rentCheck",
        operator: CondOperator.IN,
        value: filters.rentCheck?.join(","),
      })
    }

    if (filters.price?.[0] > MIN) {
      tempFilters.push({
        field: "price",
        operator: CondOperator.GREATER_THAN_EQUALS,
        value: filters.price?.[0],
      })
    }

    if (filters.price?.[1] < MAX) {
      tempFilters.push({
        field: "price",
        operator: CondOperator.LOWER_THAN_EQUALS,
        value: filters.price?.[1],
      })
    }

    if (filters.size?.min) {
      tempFilters.push({
        field: "size",
        operator: CondOperator.GREATER_THAN_EQUALS,
        value: filters.size?.min,
      })
    }

    if (filters.size?.max) {
      tempFilters.push({
        field: "size",
        operator: CondOperator.LOWER_THAN_EQUALS,
        value: filters.size?.max,
      })
    }

    return tempFilters
  }, [filters])

  return apiFilters
}
