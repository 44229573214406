import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { FormattedMessage } from "react-intl"
import MinusIcon from "../assets/icons/minusIcon"
import PlusIcon from "../assets/icons/plusIcon"
import { error, truncate } from "../utils"
import dataApi from "../_api/dataApi"
import Button from "./button"

const PriceListUploader = ({ setFile, file, type, classNames = "" }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: `image/*,application/pdf`,
    multiple: false,
    onDrop: acceptedFiles => {
      const file = acceptedFiles?.[0] || {}
      uploadfile(file)
    },
  })

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  function onUploadProgress(progressEvent) {
    if (progressEvent.total > 0) {
      const percentage = progressEvent.loaded / progressEvent.total
      setProgress(Math.round(percentage * 100))
    }
  }

  const uploadfile = fileToUpload => {
    if (!fileToUpload || fileToUpload?.id) return
    setLoading(true)
    dataApi
      .upload(fileToUpload, type, { onUploadProgress })
      .then(res => {
        if (res) {
          setFile({
            name: fileToUpload?.name,
            ...res,
          })
        } else {
          setFile("")
          error()
        }
      })
      .catch(() => {
        error(
          <FormattedMessage defaultMessage="File couldn't be uploaded, please make sure its size doesn't exceed the allowed size" />
        )
      })
      .finally(() => {
        setLoading(false)
        setProgress(0)
      })
  }

  console.log(file)
  const removeFile = e => {
    e.stopPropagation()
    setFile("")
  }

  return (
    <div className={classNames} {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        disabled={loading}
        color={file?.id ? "secondary" : "primary"}
        className="relative flex-1 w-100 h-100"
      >
        {loading && (
          <div
            className="absolute h-100 br8 left-0 transition-all secondaryBg"
            style={{ width: `${progress}%` }}
          />
        )}
        {!file?.name && !loading && (
          <>
            <FormattedMessage defaultMessage="Upload your catalog instead" />
            <PlusIcon className="mx-2" />
          </>
        )}
        {loading && (
          <div style={{ zIndex: 2 }}>
            <FormattedMessage defaultMessage="Uploading" />
            {` (${progress}%) `}
          </div>
        )}
        {Boolean(file?.name) && !loading && (
          <>
            <span title={file.name}>
              <FormattedMessage
                defaultMessage="Uploaded: {fileName}"
                values={{
                  fileName: truncate(file.name, 14),
                }}
              />
            </span>
            <span
              role="button"
              className="white pointer"
              disabled={loading}
              onClickCapture={removeFile}
            >
              <MinusIcon className="ms-3" />
            </span>
          </>
        )}
      </Button>
    </div>
  )
}

export default PriceListUploader
