import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import isEmpty from "lodash/isEmpty"
import useForm from "../../components/hooksComponents/useForm"
import ImageComponent from "../../components/imageComponent"
import { updateMyProfile } from "../../actions/profileActions"
import api from "../../_api/files"
import { setConfirmModal } from "../../actions/settingsActions"
import { navigate } from "gatsby"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { FormattedMessage, useIntl } from "react-intl"
import ArrowRight from "../../assets/icons/arrowRight"
import placeholder from "../../assets/images/placeholder1.svg"
import { connect } from "react-redux"
import { error, isValidEmail, isValidSyrianPhoneNumber } from "../../utils"
import dataApi from "../../_api/dataApi"
import AssetsUploader from "../../components/assetsUploader"

const EditProfile = ({ user, ...props }) => {
  const { state, setState, onChange, setErrors, errors, onChangePhone } =
    useForm({
      bio: "",
      name: "",
      phoneNumber: "",
      avatar: "",
      email: "",
    })
  const [avatarError, setAvatarError] = useState()
  const intl = useIntl()

  const handleOnChangePhone = val => {
    onChangePhone(val, "phoneNumber")
  }

  useEffect(() => {
    if (user) {
      setState({
        bio: user?.bio || state?.bio,
        name: user?.name || state?.name,
        phoneNumber: user?.phoneNumber || state?.phoneNumber,
        avatar: user?.avatar || state?.avatar,
        email: user?.email || state?.email,
      })
    }
  }, [user])

  useEffect(() => {
    if (state?.avatar?.id) {
      setAvatarError("")
    }
  }, [state.avatar])

  console.log(state)

  const onUpdateProfile = () => {
    if (!state?.name) {
      setErrors({
        ...errors,
        required: { name: true },
      })
      return
    }
    if (!state?.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      return
    }

    if (!isValidEmail(state?.email)) {
      setErrors({
        ...errors,
        invalid: { email: true },
      })
      return
    }
    if (!(/^[+|00]*963]{0,1}[-\s\./0-9].{6,16}$/).test(state?.phoneNumber)) {
      setErrors({
        ...errors,
        invalid: { phoneNumber: true },
      })
      return
    }

    if (state?.avatar && !state?.avatar?.id) {
      setAvatarError(
        <FormattedMessage defaultMessage="Please upload the avatar file before moving on" />
      )
      return
    }

    props.updateMyProfile(state)
  }

  const handleCancelEdit = () => {
    props.setConfirmModal({
      open: true,
      props: {
        title: <FormattedMessage defaultMessage="Cancel Edit" />,
        msg: (
          <FormattedMessage defaultMessage="Are you sure you want to cancel editing your profile?" />
        ),
        onConfirm: () => navigate("/app/my-profile"),
      },
    })
  }

  return (
    <Layout>
      <SEO title="Edit My Profile" />
      <div className="greyXLightBg">
        <div className="p-5">
          <div className="row editMyProfileAndTabsBox">
            <div className="col-lg-9 col-12 whiteBg editMyProfileBox">
              <div className="p-lg-4 p-3">
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <div className="my-2">
                      <AssetsUploader
                        multiple={false}
                        setFiles={val => setState({ ...state, avatar: val })}
                        files={state?.avatar}
                        acceptVideo={false}
                        type="avatar"
                      />
                      {avatarError && (
                        <div className="error my-2">{avatarError}</div>
                      )}
                      {/* <div className="font-bold px20 secondary text-center my-2">
                        <FormattedMessage defaultMessage="Replace Image" />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-10 col-12">
                    <form className="w-xl-50" onSubmit={onUpdateProfile}>
                      <div className="mb-4">
                        <div className="greyLight32 px16 mx-auto">
                          <FormattedMessage defaultMessage="My Name" />
                        </div>
                        <div className="signInInput greyLightBg br4 d-flex align-items-center mt-1">
                          <input
                            name="name"
                            type="text"
                            className="greyLightBg br4 flex-grow-1 px18"
                            autoComplete="off"
                            placeholder={intl.formatMessage({
                              defaultMessage: "My Name",
                            })}
                            value={state?.name}
                            onChange={onChange}
                          />
                        </div>
                        {errors.required.name && (
                          <span className="error mx-2">
                            <FormattedMessage defaultMessage="Name is required!!" />
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <div className="greyLight32 px16 mx-auto">
                          <FormattedMessage defaultMessage="My Email" />
                        </div>
                        <div className="signInInput greyLightBg br4 d-flex align-items-center mt-1">
                          <input
                            name="email"
                            type="text"
                            className="greyLightBg br4 flex-grow-1 px18"
                            autoComplete="off"
                            placeholder={intl.formatMessage({
                              defaultMessage: "My Email",
                            })}
                            value={state?.email}
                            onChange={onChange}
                          />
                        </div>
                        {errors.required.email && (
                          <span className="error mx-2">
                            <FormattedMessage defaultMessage="Email is required!!" />
                          </span>
                        )}
                        {errors.invalid.email && (
                          <span className="error mx-2">
                            <FormattedMessage defaultMessage="Email is invalid!!" />
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <div className="greyLight32 px16 mx-auto mb-1">
                          <FormattedMessage defaultMessage="My Phone Number" />
                        </div>
                        <PhoneInput
                          inputClass={"profile-phoneInput px18"}
                          dropdownClass={"profile-phoneDropDown px18"}
                          buttonClass={"profile-phoneFlag"}
                          country={"sy"}
                          value={state?.phoneNumber}
                          onChange={(value, data, _, formattedValue) => {
                            console.log(formattedValue)
                            const newVal = formattedValue?.split(" ")?.join("")
                            handleOnChangePhone(newVal)
                          }}
                          disableDropdown
                          enableAreaCodes={true}
                          countryCodeEditable={false}
                          onlyCountries={["sy"]}
                          masks={{ sy: "........." }}
                        />
                        {errors.invalid?.phoneNumber && (
                          <span className="error mx-2">
                            <FormattedMessage defaultMessage="Phone number is invalid!!" />
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <div className="greyLight32 px16 mx-auto">
                          <FormattedMessage defaultMessage="About Me" />
                        </div>
                        <div className="signInInput greyLightBg br4 d-flex align-items-center mt-1">
                          <textarea
                            name="bio"
                            className="greyLightBg br4 flex-grow-1 px18"
                            placeholder="About Me"
                            rows="5"
                            cols="4"
                            value={state?.bio}
                            onChange={onChange}
                          />
                        </div>
                        {errors.required.bio && (
                          <span className="error mx-2">
                            <FormattedMessage defaultMessage="Bio is required!!" />
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-12 whiteBg br8 my-5 my-lg-0 singlePropertyBoxOwner">
              <div className="p-lg-4 p-3">
                <div
                  className="primary font-bold px16 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
                  onClick={handleCancelEdit}
                >
                  <span className="me-3">
                    <FormattedMessage defaultMessage="Cancel" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </div>
                <div
                  className="white secondaryBg font-bold px16 p-3 br8 mb-4 pointer w-100 d-flex justify-content-between align-items-center"
                  onClick={onUpdateProfile}
                >
                  <span className="me-3">
                    <FormattedMessage defaultMessage="Save Updates" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ user })
export default connect(mapStateToProps, { updateMyProfile, setConfirmModal })(
  EditProfile
)
