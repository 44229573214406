import React from "react"
import { FormattedMessage } from "react-intl"
import FieldDesc from "../../propertyForm/fieldDesc"

const FourthStep = () => {
  return (
    <div className="res-w-3/4 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Ad is ready for publishing, our team will get in touch with for the payment and more information" />
      </FieldDesc>
      <div className="px20 darkGrey mx-auto " style={{ width: "80%" }}>
        <p>
          <FormattedMessage defaultMessage="Ad will be reviewed before buplishing, so we keep everyone safe and happy." />
        </p>
        <p>
          <FormattedMessage defaultMessage="your ad will not go live if it:" />
        </p>
        <ol>
          <li>
            <p>
              <FormattedMessage defaultMessage="violates any goverments lows" />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessage defaultMessage="in the wrong category" />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessage defaultMessage="placed multiple times, or in multiple categories" />
            </p>
          </li>
          <li>
            <p>
              <FormattedMessage defaultMessage="with fraudutent or misleading information" />
            </p>
          </li>
        </ol>
        <div>
          <p className="mt-5">
            <FormattedMessage defaultMessage="for more information read our" />
          </p>
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            <p className="font-bold">
              <FormattedMessage defaultMessage="Terms and conditions" />
            </p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default FourthStep
