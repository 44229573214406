import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { connect } from "react-redux"
import Loader from "../components/loader"
import placeholder from "../assets/images/placeholder1.svg"
import details from "../assets/images/propertiesImages/details.png"
import details2 from "../assets/images/propertiesImages/details2.png"
import { Phone } from "../assets/images/projectsIcons/phone"
import { Email } from "../assets/images/projectsIcons/email"
import { Global } from "../assets/images/global"
import { Home } from "../assets/images/projectsIcons/home"
import { File } from "../assets/images/projectsIcons/file"
import prices from "../assets/images/prices.png"
import { FormattedMessage } from "react-intl"
import dataApi from "../_api/dataApi"
import { error } from "../utils"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"
import { Box } from "@material-ui/core"
import ServiceActionButtons from "../components/serviceActionButtons"
import ServiceSingleActionButtons from "../components/serviceSingleActionButtons"
import { setConfirmModal } from "../actions/settingsActions"

const Service = props => {
  const { id, entityData, loading } = props
  const { lang } = props
  const [showContacts, setShowContacts] = useState(false)
  const [service, setService] = useState({})
  // const {
  //   propImage,
  //   offerType,
  //   statusInfo,
  //   user
  // } = useService(service)
  const isMobile = useMediaQuery("(max-width: 528px)")
  const isMD = useMediaQuery("(max-width: 1199px)")
  const status = service?.status

  const items = [details, details2, details, details2, details2]
  const thumbItems = (items, setCurrentImage) => {
    return items.map((item, i) => (
      <img
        src={item}
        key={i}
        alt={`thumb-${i}`}
        className="w-100 br8 pointer"
        onClick={() => setCurrentImage(item)}
      />
    ))
  }

  useEffect(() => {
    // props.setLoading(true)
    dataApi
      .getSingleService(id)
      .then(res => {
        res && setService(res)
      })
      .catch(() => {
        error()
      })
      .finally(() => {
        // props.setLoading(false)
      })
  }, [id, lang])

  const [currentImage, setCurrentImage] = useState(items[0])
  const [thumbs] = useState(thumbItems(items, setCurrentImage))

  return (
    <Layout>
      <SEO
        title={entityData?.name || "Service"}
        image={entityData?.images?.length && entityData?.images[0]}
        description={entityData?.description}
      />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="p-2 p-lg-5">
          <div className="row justify-content-around">
            <div
              className="col-xl-9 col-12 whiteBg br8"
              style={!isMD ? { marginLeft: "20px" } : { marginBottom: 20 }}
            >
              <div className="row p-lg-4 p-3" style={{ padding: "2rem" }}>
                <div className="col-lg-7 col-12">
                  <div className="d-flex align-items-center mb-4 flex-wrap flex-lg-nowrap">
                    <img
                      style={{
                        width: 186,
                        height: 186,
                        objectFit: "cover",
                        borderRadius: "8px",
                        marginRight: 20,
                      }}
                      src={
                        service && service.logo && service.logo.url
                          ? service.logo.url
                          : placeholder
                      }
                      alt="service"
                      width="50%"
                      height="50%"
                      className="mb-4 mb-lg-0"
                    />
                    <div className="mr-lg-5" style={{ maxWidth: "100%" }}>
                      <div className="px20 black mb-2">Carpentry</div>
                      <div className="font-bold px32 black mb-2">
                        {service.title}
                      </div>
                      <Box
                        style={{ width: "100%", overflowWrap: "break-word" }}
                      >
                        <div
                          className="px20 darkGrey"
                          style={{ maxWidth: "100%" }}
                        >
                          {service.description}
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="darkGrey px26 font-bold mb-3">
                      <FormattedMessage defaultMessage="More details" />
                    </div>
                    <div className="d-flex flex-wrap flex-lg-nowrap">
                      <div style={{ width: "300px" }}>
                        <div
                          className="darkGrey d-flex align-items-center mb-3 px20"
                          style={{ marginTop: "30px" }}
                        >
                          <Phone />
                          <span className="mx-2">{service.phone}</span>
                        </div>
                        <div
                          className="darkGrey d-flex align-items-center mb-3 px20"
                          style={{ marginTop: "30px" }}
                        >
                          <Email />
                          <span className="mx-2">{service.email}</span>
                        </div>
                        <div
                          className="darkGrey d-flex align-items-center mb-lg-3 px20"
                          style={{ marginTop: "30px" }}
                        >
                          <Global />
                          <span className="mx-2">{service.website_link}</span>
                        </div>
                      </div>
                      <div>
                        <div
                          className="darkGrey d-flex mx-lg-4 mx-xl-2 mr-0 align-items-center mb-3 px20"
                          style={{ marginTop: "30px" }}
                        >
                          <Home />
                          <span className="mx-2">{service.address}</span>
                        </div>
                        <div
                          className="darkGrey d-flex mx-lg-4 mx-xl-2 mr-0 align-items-center mb-3 px20"
                          style={{ marginTop: isMD ? "20px" : "30px" }}
                        >
                          <File />
                          <span className="mx-2 w-75">
                            Available on{" "}
                            {service.working_days?.Sun === 1 ? "Sun, " : null}
                            {service.working_days?.Mon === 1 ? "Mon, " : null}
                            {service.working_days?.Tue === 1 ? "Tue, " : null}
                            {service.working_days?.Wed === 1 ? "Wed, " : null}
                            {service.working_days?.Thu === 1 ? "Thu, " : null}
                            {service.working_days?.Fri === 1 ? "Fri, " : null}
                            {service.working_days?.Sat === 1 ? "Sat, " : null}
                            From{" "}
                            {service.working_hours &&
                            service.working_hours[0].from
                              ? service.working_hours[0].from
                              : "0:00"}
                            AM to{" "}
                            {service.working_hours &&
                            service.working_hours[0].to
                              ? service.working_hours[0].to
                              : "0:00"}
                            PM
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="darkGrey px26 font-bold mb-3">
                      <FormattedMessage defaultMessage="Services and prices" />
                    </div>
                    {service &&
                    service.service_options &&
                    Array.isArray(service.service_options) &&
                    service.service_options.length > 0
                      ? service.service_options.map((item, index) => (
                          <div className="row">
                            <div className="col darkGrey d-flex align-items-center mb-3 px20">
                              {item.title}
                            </div>
                            <div className="col darkGrey d-flex align-items-center mb-3 px20">
                              SP {item.price}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-lg-5 col-12 mb-3">
                  <div className="px20 black mb-3 cursor-default">
                    <FormattedMessage defaultMessage="Download our prices list" />
                  </div>
                  <div className="pointer">
                    <a href={prices} download>
                      <img
                        alt="prices"
                        src={prices}
                        width="100%"
                        height="92%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-xl-2 col-12 whiteBg br8 my-5 p-0 my-lg-0 ${
                isMD ? "h-25vh" : "h-50vh"
              }`}
              style={isMD ? null : { marginRight: "10px" }}
            >
              <ServiceSingleActionButtons
                service={service}
                setConfirmModal={props.setConfirmModal}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default connect(null, { setConfirmModal })(Service)
