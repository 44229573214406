import React from "react"
import { FavoriteIcon } from "../assets/images/propertiesImages/favoriteIcon"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon"
import Spinner from "../assets/icons/spinner"
import useService from "./useService"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"

const ServiceSingleActionButtons = ({ service, setConfirmModal }) => {
  const {
    isFav,
    isFavLoading,
    toggleFavorite,
    editService,
    deleteService,
    shareService,
    user,
  } = useService(service)

  const isOwner = user?.id === service?.user?.id

  const handleToggleFavClick = e => {
    e.stopPropagation()
    toggleFavorite()
  }

  const handleShareClick = e => {
    e.stopPropagation()
    shareService()
  }

  const handleEditService = e => {
    e.stopPropagation()
    editService(service.id)
  }
  const handleDeleteService = e => {
    e.stopPropagation()
    setConfirmModal({
      open: true,
      props: {
        title: <FormattedMessage defaultMessage="Delete Service" />,
        msg: (
          <FormattedMessage defaultMessage="Are you sure you want to delete this service, you will lose all its information?" />
        ),
        onConfirm: deleteService,
      },
    })
  }

  return (
    <div>
      <div className="p-lg-4 p-3">
        <div
          onClick={handleShareClick}
          className="primary font-bold px16-service-responsive p-3 br8 custom-border-primary pointer w-100 d-flex justify-content-between align-items-center"
        >
          <span className="me-1 px16-service-responsive">
            <FormattedMessage defaultMessage="Share Service" />
          </span>
          <ArrowRight size="1.5rem" />
        </div>
      </div>
      {!isOwner && (
        <div className="p-lg-4 pt-lg-0 p-3 pt-0">
          <div
            onClick={handleToggleFavClick}
            className="primary font-bold px16-service-responsive p-3 br8 custom-border-primary mb-0 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-1 px16-service-responsive">
              {isFavLoading && <Spinner />}
              {!isFavLoading &&
                (!isFav ? (
                  <FormattedMessage defaultMessage="Set Favorite" />
                ) : (
                  <FormattedMessage defaultMessage="Remove Favorite" />
                ))}
            </span>
            <ArrowRight size="1.5rem" />
          </div>
        </div>
      )}
      {isOwner && (
        <>
          <div className="p-lg-4 pt-lg-0 p-3 pt-0">
            <div
              onClick={handleEditService}
              className="primary font-bold px16-service-responsive p-3 br8 custom-border-primary
              mb-0 pointer w-100 d-flex justify-content-between align-items-center"
            >
              <span className="me-1 px16-service-responsive">
                <FormattedMessage defaultMessage="Edit Service" />
              </span>
              <ArrowRight size="1.5rem" />
            </div>
          </div>
          <div className="p-lg-4 pt-lg-0 p-3 pt-0">
            <div
              onClick={handleDeleteService}
              className={`primary font-bold px16-service-responsive p-3 br8 custom-border-primary ${
                !isOwner ? "mb-0" : "mb-4"
              } pointer w-100 d-flex justify-content-between align-items-center`}
            >
              <span className="me-1 px16-service-responsive">
                <FormattedMessage defaultMessage="Delete Service" />
              </span>
              <ArrowRight size="1.5rem" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ServiceSingleActionButtons
