import React, { useContext, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import ChevRight from "../../../assets/icons/chevRight"
import dataApi from "../../../_api/dataApi"
import CheckboxGroup from "../../propertyForm/checkboxGroup"
import FieldDesc from "../../propertyForm/fieldDesc"
import FormInput from "../../propertyForm/formInput"
import FormSelect from "../../propertyForm/formSelect"
import ListServiceContext from "../serviceContext"

const daysOptions = [
  { label: <FormattedMessage defaultMessage="Saturday" />, value: "Sat" },
  { label: <FormattedMessage defaultMessage="Sunday" />, value: "Sun" },
  { label: <FormattedMessage defaultMessage="Monday" />, value: "Mon" },
  { label: <FormattedMessage defaultMessage="Tuesday" />, value: "Tue" },
  {
    label: <FormattedMessage defaultMessage="Wednesday" />,
    value: "Wed",
  },
  { label: <FormattedMessage defaultMessage="Thursday" />, value: "Thu" },
  { label: <FormattedMessage defaultMessage="Friday" />, value: "Fri" },
]

const PopupButton = ({ children, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="br8 grey px20 d-flex align-items-center justify-content-between flex-1"
    style={{
      border: "1px solid #BFC7D2",
      width: "478px",
      height: "48px",
      color: "#2D3436",
    }}
  >
    {children}
    <ChevRight style={{ marginRight: "10px" }} />
  </button>
)

const serviceCategoryOptions = [{ value: "", label: "Test" }]

const SecondStep = () => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListServiceContext)
  const [categories, setCategories] = useState([])
  const handleServiceCatChange = ({ value }) => {
    setFieldValue("category", value)
  }

  useEffect(() => {
    setCurrentStepInputs([
      "category",
      "address",
      "website_link",
      "description",
      "working_days",
      "working_hours",
    ])
  }, [setCurrentStepInputs])
  const changeFromTo = (type, value) => {
    let working_hours =
      values.working_hours && values.working_hours.length > 0
        ? values.working_hours
        : []
    console.log(value.target.value)

    if (type === "from") {
      working_hours[0].from = value.target.value
      setFieldValue("working_hours", working_hours)
    }
    if (type === "to") {
      working_hours[0].to = value.target.value
      setFieldValue("working_hours", working_hours)
    }
  }
  useEffect(() => {
    dataApi.getServiceCategory().then(res => {
      setCategories(res.data)
    })
  }, [])
  console.log(errors)

  return (
    <div className="res-w-3/4 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Service Type" />
      </FieldDesc>
      <div className="d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row">
        <div className="res-w-2/5">
          <div>
            {/* <PopupButton onClick={null} children={null}>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "32px",
                }}
              >
                Select category
              </div>
            </PopupButton> */}

            {categories && categories.length > 0 ? (
              <FormSelect
                label={<FormattedMessage defaultMessage="Service Category" />}
                name="category"
                addProperty={true}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select Category",
                })}
                outerClassName="w-100"
                style={{ height: "48px" }}
                value={
                  values.category && categories.length > 0
                    ? {
                        value: values.category,
                        label: categories.find(
                          cat =>
                            cat.id ===
                            (values.category && values.category.id
                              ? values.category.id
                              : values.category)
                        )?.title,
                      }
                    : ""
                }
                onChange={handleServiceCatChange}
                onBlur={handleBlur}
                options={categories.map(item => {
                  return { label: item.title, value: item.id }
                })}
                error={touched.category && errors.category}
              />
            ) : (
              <FormSelect
                label={<FormattedMessage defaultMessage="Service Category" />}
                name="category"
                placeholder={intl.formatMessage({
                  defaultMessage: "Select Category",
                })}
                style={{ height: "48px" }}
                outerClassName="w-100"
                value={null}
                options={[]}
              />
            )}
            {touched.category && <span className="error mx-2"></span>}
          </div>
          <div style={{ width: "100%" }}>
            <FormInput
              label={<FormattedMessage defaultMessage="Add your address" />}
              name="address"
              placeholder={intl.formatMessage({
                defaultMessage: "Address",
              })}
              style={{ height: "48px" }}
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.address && errors.address}
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormInput
              label={
                <FormattedMessage defaultMessage="Add your website link" />
              }
              name="website_link"
              placeholder={intl.formatMessage({
                defaultMessage: "Website link",
              })}
              style={{ height: "48px" }}
              value={values.website_link}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.website_link && errors.website_link}
            />
          </div>
          <div style={{ width: "100%", minHeight: "118px" }}>
            <FormInput
              label={<FormattedMessage defaultMessage="Service description" />}
              name="description"
              tag="textarea"
              minLength={2}
              placeholder={intl.formatMessage({
                defaultMessage: "Add a short description for your service",
              })}
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && errors.description}
            />
          </div>
        </div>

        <div className="res-w-2/5">
          <CheckboxGroup
            className="w-100"
            label={
              <FormattedMessage defaultMessage="When do you offer your service?" />
            }
            options={daysOptions}
            value={values.working_days}
            error={touched.working_days && errors.working_days}
            handleChange={(value, checked) => {
              if (value === "Mon") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Mon: checked ? 1 : 0,
                })
              }
              if (value === "Tue") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Tue: checked ? 1 : 0,
                })
              }
              if (value === "Wed") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Wed: checked ? 1 : 0,
                })
              }
              if (value === "Thu") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Thu: checked ? 1 : 0,
                })
              }
              if (value === "Fri") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Fri: checked ? 1 : 0,
                })
              }
              if (value === "Sat") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Sat: checked ? 1 : 0,
                })
              }
              if (value === "Sun") {
                setFieldValue("working_days", {
                  ...values.working_days,
                  Sun: checked ? 1 : 0,
                })
              }
            }}
            getChecked={option => {
              if (option.value === "Mon" && values.working_days.Mon === 1) {
                return true
              } else if (
                option.value === "Tue" &&
                values.working_days.Tue === 1
              ) {
                return true
              } else if (
                option.value === "Wed" &&
                values.working_days.Wed === 1
              ) {
                return true
              } else if (
                option.value === "Thu" &&
                values.working_days.Thu === 1
              ) {
                return true
              } else if (
                option.value === "Fri" &&
                values.working_days.Fri === 1
              ) {
                return true
              } else if (
                option.value === "Sat" &&
                values.working_days.Sat === 1
              ) {
                return true
              }
              if (option.value === "Sun" && values.working_days.Sun === 1) {
                return true
              } else {
                return false
              }
            }}
          />

          <div
            style={{ gap: 10 }}
            className="d-flex justify-content-between align-items-center"
          >
            <FormInput
              label={<FormattedMessage defaultMessage="From" />}
              name="working_hours[0].from"
              type="time"
              placeholder={intl.formatMessage({
                defaultMessage: "From",
              })}
              style={{ height: "48px", width: "100%" }}
              outerClassName="w-50 fixedHeight"
              value={
                values.working_hours &&
                values.working_hours[0] &&
                values.working_hours[0].from
              }
              onChange={e => changeFromTo("from", e)}
              onBlur={handleBlur}
              error={
                touched.working_hours &&
                errors.working_hours &&
                errors.working_hours[0] &&
                errors.working_hours[0]?.from
              }
            />

            <FormInput
              label=" "
              name="working_hours[0].to"
              type="time"
              placeholder={intl.formatMessage({
                defaultMessage: "To",
              })}
              style={{ height: "48px", width: "100%" }}
              outerClassName="w-50 fixedHeight"
              value={
                values.working_hours &&
                values.working_hours[0] &&
                values.working_hours[0].to
              }
              S
              onChange={e => changeFromTo("to", e)}
              onBlur={handleBlur}
              error={
                touched.working_hours &&
                errors.working_hours &&
                errors.working_hours[0] &&
                errors.working_hours[0]?.to
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondStep
