import React from "react"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const user = useSelector(state => state?.user?.user)

  if (!user && location.pathname !== `/`) {
    if (typeof window !== "undefined") {
      navigate("/")
    }
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
