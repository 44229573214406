import React, { Fragment, useState } from "react"
import placeholder from "../assets/images/placeholder1.svg"
import { Home } from "../assets/images/projectsIcons/home"
import { Phone } from "../assets/images/projectsIcons/phone"
import { Email } from "../assets/images/projectsIcons/email"
import { FavoriteIcon } from "../assets/images/propertiesImages/favoriteIcon.js"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon.js"
import { setGeneralModal, toggleModal } from "../actions/settingsActions"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import dataApi from "../_api/dataApi"
import { error, success } from "../utils"
import ShareModal from "./modals/shareModal"

const AgentComponent = props => {
  const { user, agentData } = props
  const [isFav, setIsFav] = useState(agentData.isFollowed)

  /*  const addToFavoriteHandler = productId => {
      if(!user){
        props.toggleModal({ open: true })
        return
      }
      api.addToFavorites(productId).then(() => {
        setIsFav(isFav ? false : true)
        NotificationManager.success(isFav ? 'Removed from favorite list' : 'Added to favorite list', '', 3000)
      })
    }*/
  const toggleFollow = () => {
    // setLoading(true)
    dataApi
      .toggleFollow(agentData?.id)
      .then(() => {
        setIsFav(isFav ? false : true)
        isFav
          ? success(
              <FormattedMessage
                defaultMessage="You unfollowed {name}"
                values={{ name: agentData.name }}
              />
            )
          : success(
              <FormattedMessage
                defaultMessage="You are following {name}"
                values={{ name: agentData.name }}
              />
            )
      })
      .catch(() => error())
      .finally(() => {
        // setLoading(false);
      })
  }
  const cardAction = () => {
    if (!user) {
      props.toggleModal({ open: true })
      return
    }
  }

  return (
    <Fragment>
      <div
        className="col-lg-6 col-12 d-lg-flex d-none my-3"
        style={{ width: "100%", height: "" }}
      >
        <div className="whiteBg br8 p-3 w-100 card-effect">
          <div className="row" style={{ marginTop: "-7px" }}>
            <div className="col-lg-4 d-flex align-items-center justify-content-center">
              <div>
                <img
                  src={
                    (agentData && agentData.avatar && agentData.avatar.url) ||
                    placeholder
                  }
                  alt="agent"
                  width="100%"
                  height="100%"
                  className="pointer br8"
                  // onClick={() => navigate(`/app/property/${agentData.id}`)}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mx-1">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="font-bold px22 py-lg-2">{agentData.name}</div>
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      onClick={() => props.shareProfile(agentData.id)}
                      className="mx-lg-0 agentsShare" /*onClick={() => addToFavoriteHandler(productData.id)}*/
                    >
                      <ShareIcon className="agentsShare"/>
                    </button>
                    <button
                     className="agentsFollow"
                      onClick={
                        toggleFollow
                      } /*onClick={() => addToFavoriteHandler(productData.id)}*/
                    >
                      <FavoriteIcon className="agentsFollow" color={isFav ? "#FC4B67" : ""} />
                    </button>
                  </div>
                </div>

                <div className="mt-lg-5 mt-3" style={{ marginBottom: "10px" }}>
                  <div className="mb-3 d-flex align-items-center">
                    <Phone />
                    <span className="darkGrey font-bold mx-2 px16">
                      {agentData.phoneNumber}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Email />
                    <span className="darkGrey mx-2 px16">
                      {agentData.email}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="mt-3 d-flex align-items-center">
                      <Home />
                      <span className="darkGrey mx-2 px16">101 Property</span>
                    </div>
                    <div className="secondaryLightBg py-2 br8 w-sm-100 card-button pointer m-top">
                      <button
                        onClick={() =>
                          navigate(`/app/profile/${agentData?.id}`)
                        }
                        className="d-flex justify-content-between align-items-center flex-wrap font-bold secondary card-button w-sm-100"
                      >
                        <span className="px20 mx-2">
                          <FormattedMessage defaultMessage="Details" />
                        </span>
                        <span className="mx-2">
                          <ArrowRight size="1.5rem" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-12 d-block d-lg-none my-3 align-items-center"
        style={{ width: "100%" }}
      >
        <div className="whiteBg br8 h-100 w-100 p-3 card-effect">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={agentData.image || placeholder}
              alt="agent"
              width="auto"
              height="100%"
              className="pointer br8"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <div className="font-bold px22 py-lg-2">{agentData.name}</div>
            <div className="d-flex align-items-center justify-content-end">
              <button
                onClick={() => props.shareProfile(agentData.id)}
                className="mx-1 mx-lg-2" /*onClick={() => addToFavoriteHandler(productData.id)}*/
              >
                <ShareIcon />
              </button>
              <button /*onClick={() => addToFavoriteHandler(productData.id)}*/>
                <FavoriteIcon color={isFav ? "#FC4B67" : ""} />
              </button>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-start mb-3">
              <Phone />
              <span className="darkGrey font-bold mx-2 px16">
                {agentData.phoneNumber}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-start mb-3">
              <Email />
              <span className="darkGrey mx-2 px16">{agentData.email}</span>
            </div>
            <div className="d-flex align-items-end justify-content-between flex-wrap">
              <div className="d-flex align-items-center justify-content-start">
                <Home />
                <span className="darkGrey mx-2 px16">101 Property</span>
              </div>
            </div>
            <div className="secondaryLightBg py-2 br8 card-button w-100 m-top pointer">
              <button
                onClick={() => navigate(`/app/profile/${agentData?.id}`)}
                className="d-flex justify-content-between align-items-center flex-wrap font-bold secondary card-button w-100"
              >
                <span className="px20 mx-2">Details</span>
                <span className="mx-2">
                  <ArrowRight size="1.5rem" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ user })
export default connect(mapStateToProps, { toggleModal })(AgentComponent)
