import React from "react"

const StepIndicator = ({ active, onClick }) => (
  <div
    role={onClick ? "button" : "none"}
    className={`step-indicator ${onClick ? "pointer" : ""} ${
      active ? "active" : ""
    }`}
    onClick={onClick}
  ></div>
)
const StepperBar = ({ stepsCount = 1, currentStep = 0, onClick }) => {
  const steps = Array(stepsCount).fill(1)

  return (
    <div className="w-100 d-flex stepper-bar">
      {steps.map((_, i) => (
        <StepIndicator
          key={i}
          active={currentStep === i || currentStep > i}
          onClick={typeof onClick === "function" ? () => onClick(i) : undefined}
        />
      ))}
    </div>
  )
}

export default StepperBar
