import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { FormattedMessage } from "react-intl"
import StepperBar from "../stepperBar"
import Button from "../button"
import ListPropertySteps from "./steps"
import ListPropertyContext from "./propertyContext"
import dataApi from "../../_api/dataApi"
import { error, success } from "../../utils"
import Seo from "../seo"
import { openConfirmModal, setLoading } from "../../actions/settingsActions"
import OutLineArrowLeft from "../../assets/icons/outLineArrowLeft"
import OutLineArrowRight from "../../assets/icons/outLineArrowRight"
import { navigate } from "gatsby-link"
import { connect } from "react-redux"
import usePropertyForm from "./usePropertyForm"
import LoaderComponent from "../loader"
import isEmpty from "lodash/isEmpty"
import { Tooltip } from "@material-ui/core"

const updateSteps = ListPropertySteps.slice(0, ListPropertySteps.length - 1)

const PropertyEditForm = ({ id, propertyLoading, lang, ...props }) => {
  const [property, setProperty] = useState({})
  const [loading, setLoading] = useState(false)

  const handleUpdateProperty = values => {
    setLoading(true)
    dataApi
      .updateProperty(id, values)
      .then(() => {
        success(
          <FormattedMessage defaultMessage="Property ad was updated successfully" />
        )
        navigate(`/app/property/${id}`)
      })
      .catch(err => {
        error()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const formContext = usePropertyForm(handleUpdateProperty, updateSteps)
  const {
    setValues,
    handleSubmit,
    goToNextStep,
    setStep,
    backToPrevStep,
    step,
    values,
    CurrentStepComponent,
    errors,
    touched,
  } = formContext

  useEffect(() => {
    if (id) {
      props.setLoading(true)
      dataApi
        .getPropertyById(id)
        .then(res => {
          res && setProperty(res)
        })
        .catch(() => {
          error()
        })
        .finally(() => props.setLoading(false))
    }
  }, [id, lang])

  useEffect(() => {
    property?.id &&
      setValues({
        ...values,
        ...property,
      })
  }, [property])

  const onCancelConfirm = () => {
    navigate(-1)
  }
  const onClickCancel = () => {
    props.openConfirmModal({
      title: <FormattedMessage defaultMessage="Quit Updating ad" />,
      msg: (
        <FormattedMessage defaultMessage="You will lose all the information you provided, do you want to cancel updating this Ad?" />
      ),
      onConfirm: onCancelConfirm,
    })
  }

  const isLastStep = step === updateSteps.length - 1
  const isError = !isEmpty(errors) && !isEmpty(touched)

  return (
    <ListPropertyContext.Provider value={formContext}>
      <Seo title="Update Property Ad" />
      {propertyLoading && <LoaderComponent />}
      <div className="list-property d-flex flex-column">
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mb-3">
          <h1 className="brand-text d-none d-lg-block">
            <FormattedMessage defaultMessage="SIMSAR" />
          </h1>
          <button
            className="px-0 px16 primary font-bold"
            onClick={onClickCancel}
          >
            <FormattedMessage defaultMessage="Cancel & Close" />
            <AiOutlineClose fontSize="16" className="ms-2" />
          </button>
        </div>
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between">
          <h2 className="px32 font-bold black text-decorated">
            <FormattedMessage defaultMessage="Update Property Ad" />
          </h2>
        </div>

        <div className="w-100 mt-3 pt-3 flex-grow-1 d-flex flex-column overflow-hidden">
          <StepperBar
            stepsCount={updateSteps.length}
            currentStep={step}
            onClick={setStep}
          />
          <form
            className="pt-5 d-flex flex-column flex-grow-1 overflow-hidden"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="w-100 px-1 flex-grow-1 d-flex flex-column justify-content-center align-items-center scrollable">
              <CurrentStepComponent />
            </div>
            <div className="w-100 pb-3 d-flex justify-content-between justify-content-md-end space-x-20 align-items-center">
              <Button
                disabled={step === 0}
                type="button"
                color="white"
                onClick={backToPrevStep}
              >
                <OutLineArrowLeft className="me-3 localized" />
                <FormattedMessage defaultMessage="Back" />
              </Button>

              <Button
                disabled={isLastStep}
                type="button"
                color="primary"
                onClick={goToNextStep}
              >
                <FormattedMessage defaultMessage="Next" />

                <OutLineArrowRight className="ms-3 localized" />
              </Button>

              <Tooltip
                open={isError}
                title={
                  <FormattedMessage defaultMessage="You have some errors in your inputs, please check the inputs you modified" />
                }
                classes={{
                  tooltip: "br16 px14 errorBg white w-20rem padding-20 z-5",
                }}
              >
                <Button
                  //   disabled={isError}
                  type="submit"
                  color="secondary"
                  loading={loading}
                >
                  <FormattedMessage defaultMessage="Update Ad" />
                </Button>
              </Tooltip>
            </div>
          </form>
        </div>
      </div>
    </ListPropertyContext.Provider>
  )
}

const mapStateToProps = ({ settings: { lang }, shared: { loading } }) => ({
  lang,
  propertyLoading: loading,
})
export default connect(mapStateToProps, { openConfirmModal, setLoading })(
  PropertyEditForm
)
