import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import MapComponent from "./mapComponent"
import ArrowRight from "../assets/icons/arrowRight"
import ArrowLeft from "../assets/icons/arrowLeft"

const MarkOnMap = ({ changeLocation, latLng, setGeneralModal }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    latLng ? latLng : null
  )
  const [defaultLocation, setDefaultLocation] = useState(null)
  useEffect(() => {
    if (!latLng) {
      navigator.geolocation.getCurrentPosition(position => {
        var pos = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        }
        setDefaultLocation(pos)
      })
    }
  }, [])
  return (
    <div className="markOnMapContainer h-100 d-flex flex-column align-items-center">
      <div className="w-100 d-flex flex-column flex-lg-row justify-content-between align-items-start mb-3">
        <h2 className="px32 font-bold black text-decorated">
          <FormattedMessage defaultMessage="Mark on map" />
        </h2>
        <p className="px14 darkGrey">
          <FormattedMessage defaultMessage="Mark the precise location of your property" />
        </p>
      </div>
      <div className="w-100">
        <MapComponent
          onClick={ev => {
            setSelectedLocation({
              lat: ev.latLng.lat(),
              long: ev.latLng.lng(),
            })
          }}
          lat={
            selectedLocation && selectedLocation.lat
              ? selectedLocation.lat
              : defaultLocation && defaultLocation.lat
              ? defaultLocation.lat
              : 25.2597
          }
          long={
            selectedLocation && selectedLocation.long
              ? selectedLocation.long
              : defaultLocation && defaultLocation.long
              ? defaultLocation.long
              : 55.3095
          }
          zoom={8}
          mapTitle={"test"}
          locations={
            selectedLocation && selectedLocation.lat && selectedLocation.long
              ? [{ ...selectedLocation }]
              : []
          }
          onClickMarker={location => console.log(location)}
        />
      </div>
      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-center w-100 mt-4">
        <button
          className="mx-1 justify-content-between d-flex align-items-center primary font-bold p-3 br8 button-primaryBg custom-border-primary pointer mb-3"
          onClick={() => setGeneralModal({ open: false })}
        >
          <ArrowLeft size="1.5rem" />
          <span className="ms-3">
            <FormattedMessage defaultMessage="Back to map finder" />
          </span>
        </button>
        <button
          onClick={async () => {
            await changeLocation(selectedLocation)
            setGeneralModal({ open: false })
          }}
          className="mx-1 white secondaryBg font-bold px16 p-3 br8 pointer 100 d-flex justify-content-between align-items-center button-secondaryBorder mb-sm-3"
        >
          <span className="me-3">
            <FormattedMessage defaultMessage="View more details" />
          </span>
          <ArrowRight size="1.5rem" />
        </button>
      </div>
    </div>
  )
}
export default MarkOnMap
