import React from "react"
import ReactPlayer from "react-player/file"
import PlayIcon from "../assets/icons/playIcon"

const AssetItem = ({ url, type, thumbnail }) => {
  if (type?.includes("video")) {
    return (
      <div className="w-100 relative h-100">
        <ReactPlayer
          className="asset-video br8"
          style={{
            height: "auto",
          }}
          width="100%"
          controls={!thumbnail}
          url={url}
        />
        {thumbnail && (
          <div className="absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center">
            <PlayIcon />
          </div>
        )}
      </div>
    )
  }

  return (
    <img alt={`property`} src={url} className="br8 w-100 h-100 object-cover" />
  )
}

export default AssetItem
