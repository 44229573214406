import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import { navigate } from "@reach/router"
import ArrowLeft from "../../assets/icons/arrowLeft"
import { myProfilePath } from "../../components/header"
import ProfilePropertiesComponent from "./propertiesComponent"
import profileApi from "../../_api/profile"
import { setLoading } from "../../actions/settingsActions"
import Button from "../../components/button"
import { error } from "../../utils"
import OutLineArrowLeft from "../../assets/icons/outLineArrowLeft"
import PropertyGrid from "../../components/propertyGrid"
import useMediaQuery from "../../components/hooksComponents/useMediaQuery"

const MyFavorites = props => {
  const { loading, properties, user, setLoading } = props
  const isMobile = useMediaQuery(`(max-width: 602px)`)

  const fetchMyFavorites = (config = {}, offerType) => {
    if (!config?.page || config?.page === 1) {
      setLoading(true)
    }
    return profileApi
      .getMyFavorites(config, offerType )
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(() => {
        error()
        return Promise.resolve(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <SEO title="My Favorites" />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="px-10rem text-center">
          <div className="">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ paddingTop: "110px" }}
            >
              <span className="text-decorated font-black black mx-3 myfavtitle ">
                <FormattedMessage defaultMessage="My Favorites" />
              </span>
              <Button
                color="white"
                className="custom-border-primary gotomyprofilebtn"
                onClick={() => navigate(myProfilePath)}
              >
                <OutLineArrowLeft className="localized me-2" size="1.5rem" />
                <div
                  className="d-lg-flex d-none"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  <FormattedMessage defaultMessage="Go to my profile" />
                </div>
              </Button>
            </div>
          </div>
          <ProfilePropertiesComponent
            isMobile={isMobile}
            fetchProperties={fetchMyFavorites}
            loading={loading}
            noDataPlaceholder={
              <FormattedMessage defaultMessage="You haven't added any property to your favorites list"/> 
            } 
          />
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ user: { user }, shared: { loading } }) => {
  return { user, loading }
}
export default connect(mapStateToProps, { setLoading })(MyFavorites)
