import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { connect } from "react-redux"
import Loader from "../components/loader"
import { BedIcon } from "../assets/images/propertiesImages/bedIcon"
import { BathIcon } from "../assets/images/propertiesImages/bathIcon"
import { SizeIcon } from "../assets/images/propertiesImages/sizeIcon"
import {
  setConfirmModal,
  setLoading,
  toggleCustomModal,
} from "../actions/settingsActions"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import dataApi from "../_api/dataApi"
import { error, numberWithCommas, success } from "../utils"
import ActionsButtons from "../components/propertyComponent/actionsButtons"
import useProperty from "../components/propertyComponent/useProperty"
import AssetsViewer from "../components/assetsViewer"
import { navigate } from "@reach/router"
import { Avatar } from "@material-ui/core"
import Button from "../components/button"
import { myProfilePath } from "../components/header"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"

const StatusComponent = ({ label, description, className }) => (
  <>
    <p className="px20 black">
      <FormattedMessage defaultMessage="Your ad was" />
    </p>
    <div className={`${className} br8 p-3`}>
      <div className="wrapper px-3">
        <h2 className="label font-700 px32 mb-3">{label}</h2>
        <p className="description px20 font-700">{description}</p>
      </div>
    </div>
  </>
)

const showInMapClicked = (latitude, longitude) => {
  window.open("https://maps.google.com?q=" + latitude + "," + longitude)
}

const Property = props => {
  const { loading, id, lang } = props
  const [showContacts, setShowContacts] = useState(false)
  const [property, setProperty] = useState({})

  const [isClicked, setIsClicked] = useState(user && user.isClicked)
  const clickPhone = async () => {
    setIsClicked(!isClicked)
  }
  const isMobile = useMediaQuery("(max-width: 528px)")
  const { propImage, offerType, statusInfo, user } = useProperty(property)

  const status = property?.status

  useEffect(() => {
    props.setLoading(true)
    dataApi
      .getPropertyById(id)
      .then(res => {
        res && setProperty(res)
      })
      .catch(() => {
        error()
      })
      .finally(() => props.setLoading(false))
  }, [id, lang])

  const {
    address,
    addressDetails,
    neighborhood,
    buildingNumber,
    isFurnitured,
    isConstructionCompleted,
    amenities,
    description,
    user: propUser,
  } = property || {}

  const isOwner = user?.id === propUser?.id

  const isContactInfoAvailable =
    Boolean(propUser?.emaill) || Boolean(propUser?.phoneNumber)

  const handleEditProperty = () =>
    navigate(`/app/property/${property?.id}/edit`)

  const deleteProperty = () => {
    dataApi
      .deleteProperty(id)
      .then(() => {
        success(
          <FormattedMessage defaultMessage="Property was deleted successfully" />
        )
        navigate(myProfilePath)
      })
      .catch(() => {
        error()
      })
  }
  const handleDeleteProperty = () => {
    props.setConfirmModal({
      open: true,
      props: {
        title: <FormattedMessage defaultMessage="Delete Ad" />,
        msg: (
          <FormattedMessage defaultMessage="Are you sure you want to delete this ad, you will lose all its information?" />
        ),
        onConfirm: deleteProperty,
      },
    })
  }

  return (
    <Layout>
      <SEO
        title={property?.title || "Property"}
        image={propImage}
        description={property?.description}
      />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="p-5 container-fluid">
          <div className="row singlePropertyAndOwnerBox">
            <div className="col-xl-9 whiteBg singlePropertyBox">
              <div className="row p-lg-4 p-3">
                <div className="col-lg-7 col-12 px-lg-4 my-3 my-lg-0 assetViewerContainer">
                  <AssetsViewer
                    mobileHeight={isMobile}
                    assets={property?.assets}
                  />
                </div>
                <div className="col-lg-5 col-12">
                  {status && isOwner && (
                    <div className="mb-2">
                      <StatusComponent {...statusInfo} />
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="black px20 mx-3 capitalized">
                      {offerType} •{" "}
                      {property?.relationship === "owner" ? (
                        <FormattedMessage defaultMessage="From owner" />
                      ) : (
                        <FormattedMessage defaultMessage="From broker" />
                      )}
                    </span>
                    <div>
                      <ActionsButtons property={property} />
                    </div>
                  </div>
                  <div className="secondaryLightBg py-2 br16 mb-4 w-75">
                    <button className="font-bold secondary">
                      <span className="px30 mx-2">
                        <FormattedMessage
                          defaultMessage="SP {price}"
                          values={{ price: numberWithCommas(property?.price) }}
                        />
                      </span>
                    </button>
                  </div>
                  <div className="mb-4 darkGrey px20">{property?.title}</div>
                  <div
                    className={`d-flex  ${
                      isMobile
                        ? "justify-content-start"
                        : "justify-content-between"
                    } align-items-center mb-4 ${isMobile && "flex-wrap"}`}
                  >
                    <span className="darkGrey px20 d-flex align-items-center">
                      <BedIcon />
                      <span className="mx-2">
                        <FormattedMessage
                          defaultMessage="{count} bed rooms"
                          values={{ count: property?.bedRoomsCount }}
                        />
                      </span>
                    </span>
                    <span className="darkGrey px20 d-flex align-items-center">
                      <BathIcon />
                      <span className="mx-2">
                        <FormattedMessage
                          defaultMessage="{count} baths"
                          values={{ count: property?.bathsCount }}
                        />
                      </span>
                    </span>
                    <span className="darkGrey px20 d-flex align-items-center">
                      <SizeIcon />
                      <span className="mx-2">
                        {property?.size}
                        {property?.sizeUnit?.title}
                      </span>
                    </span>
                  </div>
                  <div className="mb-4 darkGrey px20">
                    {address}
                    {neighborhood && `, ${neighborhood}`}{" "}
                    <div style={{width: "100%"}}>
                      {addressDetails && `${addressDetails}`}{" "}
                      <br/>
                      {buildingNumber && `${buildingNumber}`}
                    </div>
                  </div>
                  <div className="mb-4 font-bold px26 darkGrey">
                    <FormattedMessage defaultMessage="More details" />
                  </div>
                  <div className="mb-4 px20 darkGrey">
                    •{isFurnitured ? (
                      <FormattedMessage defaultMessage="Furnitured" />
                    ) : (
                      <FormattedMessage defaultMessage="Not Furnitured" />
                    )}{" "}
                    {" "}
                    •{isConstructionCompleted ? (
                      <FormattedMessage defaultMessage="Construction completed" />
                    ) : (
                      <FormattedMessage defaultMessage="Construction not completed" />
                    )}{" "}
                     {amenities?.map?.(a => a.title)?.join(" • ")}
                  </div>
                  {description && (
                    <div className="px20 darkGrey">
                      <span className="me-1">
                        <FormattedMessage defaultMessage="Description:" />
                      </span>
                      {description}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!loading && (
              <div className="col-xl-2 col-12 whiteBg br8 my-5 my-lg-0 singlePropertyBoxOwner">
                <div className="p-lg-4 p-3">
                  {!isOwner && (
                    <>
                      <div className="d-flex align-items-end flex-wrap mb-4">
                        <div>
                          <Avatar
                            classes={{
                              root: "user-avatar",
                            }}
                            src={propUser?.avatar?.url}
                            alt={propUser?.name}
                            variant="rounded"
                          />
                        </div>
                        <div className="black px20 m-2 text-center">
                          <div className="capitalized">
                            {propUser?.role || property?.relationship}
                          </div>
                          <div
                            className="font-bold"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {propUser?.name}
                          </div>
                        </div>
                      </div>

                      {property.lat && property.long ? (
                        <button
                          className="primary font-bold px16 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
                          onClick={() =>
                            showInMapClicked(property.lat, property.long)
                          }
                        >
                          <span className="me-3">
                            <FormattedMessage defaultMessage="View location on map" />
                          </span>
                          <ArrowRight size="1.5rem" />
                        </button>
                      ) : null}
                      <button
                        className="primary font-bold px16 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
                        onClick={() => navigate(`/app/profile/${propUser?.id}`)}
                      >
                        <span className="me-3">
                          <FormattedMessage defaultMessage="View user profile" />
                        </span>
                        <ArrowRight size="1.5rem" />
                      </button>
                      {!showContacts && isContactInfoAvailable && (
                        <div
                          className="white secondaryBg font-bold px16 p-3 br8 mb-4 pointer w-100 d-flex justify-content-between align-items-center"
                          onClick={() => setShowContacts(true)}
                        >
                          <span className="me-3">
                            <FormattedMessage
                              defaultMessage="Contact {name}"
                              values={{
                                name: propUser?.name?.split?.(" ")?.[0],
                              }}
                            />
                          </span>
                          <ArrowRight size="1.5rem" />
                        </div>
                      )}
                      {/*  <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break">
                      {propUser?.phoneNumber} Show phone number
                      </div> */}
                      {showContacts && propUser && propUser.phoneNumber ? (
                        <div
                          className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break"
                          onClick={() => clickPhone()}
                        >
                          {!isClicked ? (
                            <span className="me-3">
                              <FormattedMessage
                                defaultMessage="Show phone number"
                                // values={{ name: user?.name }}
                              />
                            </span>
                          ) : (
                            <span className="me-3">
                              <FormattedMessage
                                defaultMessage="{phone}"
                                values={{ phone: propUser?.phoneNumber }}
                              />
                            </span>
                          )}
                        </div>
                      ) : null}
                      {showContacts && (
                        <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break">
                          {propUser?.email}
                        </div>
                      )}
                      {/* {!!showContacts && isContactInfoAvailable && (
                        <div>
                          <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100">
                            {propUser?.phoneNumber}
                          </div>
                          {propUser?.email && (
                            <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100">
                              {propUser?.email}
                            </div>
                          )}
                        </div>
                      )} */}
                      <div
                        className="darkGrey px16 p-3 br8"
                        style={{ width: "110%" }}
                      >
                        <FormattedMessage defaultMessage="Never wire money or financial info to a seller on the internet. For your security, all transactions should be done in person." />
                        <div
                          onClick={() =>
                            props.toggleCustomModal({
                              customOpen: true,
                              modalType: "report",
                              title: "Report abuse",
                            })
                          }
                          className="font-bold linkHover pointer"
                          style={{ color: "#2989E1" }}
                        >
                          <FormattedMessage defaultMessage="Report Abuse" />
                        </div>
                      </div>
                    </>
                  )}

                  {isOwner && (
                    <>
                      <Button
                        color="white"
                        className="no-wrap  w-100 custom-border-primary mb-4"
                        onClick={handleDeleteProperty}
                      >
                        <span className="me-3" style={{ fontSize: "16px" }}>
                          <FormattedMessage defaultMessage="Deactivate Ad" />
                        </span>
                        <ArrowRight size="1.5rem" />
                      </Button>

                      <Button
                        color="white"
                        className="no-wrap  w-100 custom-border-primary mb-4"
                        onClick={handleDeleteProperty}
                      >
                        <span className="me-3" style={{ fontSize: "16px" }}>
                          <FormattedMessage defaultMessage="Delete Ad" />
                        </span>
                        <ArrowRight size="1.5rem" />
                      </Button>

                      <Button
                        color="secondary"
                        className="no-wrap w-100 mt-4 "
                        onClick={handleEditProperty}
                      >
                        <span className="me-3" style={{ fontSize: "16px" }}>
                          <FormattedMessage defaultMessage="Edit Ad" />
                        </span>
                        <ArrowRight size="1.5rem" />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({
  user: { user },
  shared: { loading },
  settings: { lang },
}) => ({ user, loading, lang })
export default connect(mapStateToProps, {
  toggleCustomModal,
  setConfirmModal,
  setLoading,
})(Property)
