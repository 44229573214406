import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { FormattedMessage } from "react-intl"
import StepperBar from "../stepperBar"
import Button from "../button"
import ListServiceContext from "./serviceContext"
import dataApi from "../../_api/dataApi"
import { error, success } from "../../utils"
import Seo from "../seo"
import { openConfirmModal, setLoading } from "../../actions/settingsActions"
import OutLineArrowLeft from "../../assets/icons/outLineArrowLeft"
import OutLineArrowRight from "../../assets/icons/outLineArrowRight"
import { navigate } from "gatsby-link"
import { connect } from "react-redux"
import LoaderComponent from "../loader"
import isEmpty from "lodash/isEmpty"
import { Tooltip } from "@material-ui/core"
import ListServiceSteps from "./steps"
import useMediaQuery from "../hooksComponents/useMediaQuery"
import useStepper from "../hooksComponents/useStepper"
import { uniqueId } from "lodash"
import { useFormik } from "formik"
import listServiceValidationScheme from "./validationScheme"

const updateSteps = ListServiceSteps.slice(0, ListServiceSteps.length - 1)

const ServiceEditForm = ({ id, serviceLoading, lang, ...props }) => {
  const { user } = props
  const [service, setService] = useState({})
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery("(max-width: 548px)")
  const {
    goToNextStep,
    backToPrevStep,
    CurrentStepComponent,
    step,
    safeSetStep,
  } = useStepper(ListServiceSteps)

  const [currentStepInputs, setCurrentStepInputs] = useState([])

  const handleUpdateService = values => {
    setLoading(true)
    dataApi
      .updateService(id, values)
      .then(() => {
        success(
          <FormattedMessage defaultMessage="Property ad was updated successfully" />
        )
        navigate(`/app/service/${id}`)
      })
      .catch(err => {
        error()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    submitCount,
    setErrors,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      user: "",
      category: "",
      serviceCategory: "",
      first_name: "",
      last_name: "",
      email: "",
      show_phone: false,
      phone: "",
      description: "",
      address: "",
      logo: null,
      service_options: [{ title: "", price: "", id: uniqueId("service-") }],
      website_link: "",
      working_hours: [{ from: "", to: "" }],
      working_days: { Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0 },
    },
    validationSchema: listServiceValidationScheme,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      if (!isLastStep) {
        setSubmitting(false)
        return
      }
      setLoading(true)
      const data = {
        ...values,
        logo: values.logo && values.logo.id ? values.logo.id : "",
        user: user && user.id,
        category:
          values.category && values.category.id
            ? values.category.id
            : values.category,
      }
      await handleUpdateService(data)
    },
  })
  console.log('values', values)

  useEffect(() => {
    if (id) {
      props.setLoading(true)
      dataApi
        .getSingleService(id)
        .then(res => {
          res && setService(res)
        })
        .catch(() => {
          error()
        })
        .finally(() => props.setLoading(false))
    }
  }, [id, lang])
  useEffect(() => {
    if (!isSubmitting && !isLastStep && submitCount > 0) {
      let isCurrentStepInvalid = false
      currentStepInputs.forEach(input => {
        if (errors[input]) {
          isCurrentStepInvalid = true
        }
      })
      if (step === 1) {
        if (
          values.working_days.Mon === 0 &&
          values.working_days.Tue === 0 &&
          values.working_days.Wed === 0 &&
          values.working_days.Thu === 0 &&
          values.working_days.Fri === 0 &&
          values.working_days.Sat === 0 &&
          values.working_days.Sun === 0
        ) {
          isCurrentStepInvalid = true
          setErrors({
            ...errors,
            working_days: "Please select at least one working day",
          })
        }
      }
      if (!isCurrentStepInvalid) {
        setErrors({})
        setTouched({})
        goToNextStep()
      }
    }
  }, [isSubmitting])
  useEffect(() => {
    service?.id &&
      setValues({
        ...values,
        ...service,
      })
  }, [service])

  const onCancelConfirm = () => {
    navigate(-1)
  }
  const onClickCancel = () => {
    props.openConfirmModal({
      title: <FormattedMessage defaultMessage="Quit Updating Service" />,
      msg: (
        <FormattedMessage defaultMessage="You will lose all the information you provided, do you want to cancel updating this Service?" />
      ),
      onConfirm: onCancelConfirm,
    })
  }

  const isLastStep = step === updateSteps.length - 1
  const isError = !isEmpty(errors) && !isEmpty(touched)

  return (
    <ListServiceContext.Provider
      value={{
        values,
        setValues,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setCurrentStepInputs,
      }}
    >
      <Seo title="Update Service" />
      {serviceLoading && <LoaderComponent />}
      <div className="list-property d-flex flex-column">
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mb-3">
          <h1 className="brand-text d-none d-lg-block">
            <FormattedMessage defaultMessage="SIMSAR" />
          </h1>
          <button
            className="px-0 px16 primary font-bold"
            onClick={onClickCancel}
          >
            <FormattedMessage defaultMessage="Cancel & Close" />
            <AiOutlineClose fontSize="16" className="ms-2" />
          </button>
        </div>
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between">
          <h2 className="px32 font-bold black text-decorated">
            <FormattedMessage defaultMessage="Update Property Ad" />
          </h2>
        </div>

        <div className="w-100 mt-3 pt-3 flex-grow-1 d-flex flex-column overflow-hidden">
          <StepperBar stepsCount={updateSteps.length} currentStep={step} />
          <form
            className="pt-5 d-flex flex-column flex-grow-1 overflow-hidden"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="w-100 px-1 flex-grow-1 d-flex flex-column justify-content-center align-items-center scrollable">
              <CurrentStepComponent />
            </div>
            <div className="w-100 pb-3 d-flex justify-content-between justify-content-md-end space-x-20 align-items-center">
              <Button
                disabled={step === 0}
                type="button"
                color="white"
                onClick={backToPrevStep}
              >
                <OutLineArrowLeft className="me-3 localized" />
                <FormattedMessage defaultMessage="Back" />
              </Button>

              <Button
                disabled={isLastStep}
                type="button"
                color="primary"
                onClick={goToNextStep}
              >
                <FormattedMessage defaultMessage="Next" />

                <OutLineArrowRight className="ms-3 localized" />
              </Button>

              <Tooltip
                open={isError}
                title={
                  <FormattedMessage defaultMessage="You have some errors in your inputs, please check the inputs you modified" />
                }
                classes={{
                  tooltip: "br16 px14 errorBg white w-20rem padding-20 z-5",
                }}
              >
                <Button
                  //   disabled={isError}
                  type="submit"
                  color="secondary"
                  loading={loading}
                >
                  <FormattedMessage defaultMessage="Update Service" />
                </Button>
              </Tooltip>
            </div>
          </form>
        </div>
      </div>
    </ListServiceContext.Provider>
  )
}

const mapStateToProps = ({
  user: { user },
  settings: { lang },
  shared: { loading },
}) => ({
  lang,
  user,
  serviceLoading: loading,
})
export default connect(mapStateToProps, { openConfirmModal, setLoading })(
  ServiceEditForm
)
