import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { MdDelete } from "react-icons/md"
import { FormattedMessage } from "react-intl"
import dataApi from "../_api/dataApi"
import addImagePlaceholder from "../assets/images/addImagePlaceholder.svg"
import { error, success } from "../utils"
import Button from "./button"
import nProgress from "nprogress"
import "nprogress/nprogress.css"
import AssetItem from "./assetItem"

const LOADER_PARENT_ID = "upload-loader"

nProgress.configure({
  parent: `#${LOADER_PARENT_ID}`,
  showSpinner: false,
  trickle: false,
})

function onUploadProgress(progressEvent) {
  let percentage = 0.99
  if (
    progressEvent.total > 0 &&
    progressEvent.loaded / progressEvent.total < 0.99
  ) {
    percentage = progressEvent.loaded / progressEvent.total
  }
  nProgress.isRendered() && nProgress.set(percentage)
}

const AssetsUploader = ({
  files,
  setFiles,
  multiple,
  className,
  acceptVideo,
  type = "property",
  ...props
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: !acceptVideo ? `image/*` : `image/*,video/*`,
    multiple,
    onDrop: acceptedFiles => {
      if (multiple) {
        setFiles([
          ...files,
          ...acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ])
      } else {
        const file = acceptedFiles?.[0] || {}
        setFiles(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      }
    },
  })

  const [currentViewed, setCurrentViewed] = useState({})
  const [loading, setLoading] = useState(false)

  let assetsToUpload = []
  if (multiple) {
    assetsToUpload = files?.filter?.(f => !f.id)
  } else {
    assetsToUpload = files && !Array.isArray(files) && !files?.id ? [files] : []
  }

  useEffect(() => {
    multiple && setCurrentViewed(files?.at?.(-1) || {})
  }, [files, multiple])

  const removeAsset = (e, id) => {
    e.stopPropagation()
    if (multiple) {
      setFiles(files?.filter?.(f => f.name !== id && f.id !== id))
    } else {
      setFiles(null)
    }
  }

  const handleChangeCurrentViewed = (e, asset) => {
    e.stopPropagation()
    setCurrentViewed(asset || {})
  }

  const uploadAssets = () => {
    if (assetsToUpload.length <= 0) return
    nProgress.start()
    setLoading(true)
    dataApi
      .uploadMultiple(assetsToUpload, type, { onUploadProgress })
      .then(res => {
        if (res) {
          if (multiple) {
            setFiles([...files?.filter(f => f.id), ...res.files])
          } else {
            setFiles(res?.files?.[0])
          }
          success(
            <FormattedMessage defaultMessage="Assets uploaded successfully" />
          )
        } else {
          error(
            <FormattedMessage defaultMessage="Assets couldn't be uploaded, please make sure sizes don't exceed the allowed size" />
          )
        }
      })
      .catch(() => {
        error(
          <FormattedMessage defaultMessage="Assets couldn't be uploaded, please make sure sizes don't exceed the allowed size" />
        )
      })
      .finally(() => {
        nProgress.done()
        setLoading(false)
      })
  }

  return (
    <div className="w-100">
      <Button
        type="button"
        disabled={assetsToUpload.length <= 0}
        className="mt-3"
        loading={loading}
        onClick={uploadAssets}
      >
        {loading ? (
          <FormattedMessage defaultMessage="Uploading..." />
        ) : (
          <FormattedMessage defaultMessage="Upload" />
        )}
      </Button>
      <div
        id={LOADER_PARENT_ID}
        className="my-3 w-100"
        style={{ display: loading ? "block" : "none" }}
      />
      <section
        className={`w-100 d-flex flex-column flex-lg-row asset-gallery`}
        style={{ gap: "20px" }}
      >
        {multiple && (currentViewed?.url || currentViewed?.preview) && (
          <div className="main">
            <AssetItem
              url={currentViewed?.url || currentViewed?.preview}
              type={currentViewed?.type}
            />
          </div>
        )}
        <aside className="d-flex flex-wrap justify-content-start rest">
          {multiple &&
            files?.map((file, index) => (
              <div
                style={{ width: "131px", height: "131px" }}
                key={index}
                className="relative asset-component pointer"
                role="button"
                onClick={e => handleChangeCurrentViewed(e, file)}
              >
                <AssetItem
                  url={file.preview || file.url}
                  type={file.type}
                  thumbnail
                />
                <button
                  aria-label="delete"
                  onClick={e => removeAsset(e, file.id || file.name)}
                  type="button"
                  className="absolute top-5 right-5 whiteBg error br8 p-1"
                >
                  <MdDelete size="1.5rem" />
                </button>
              </div>
            ))}

          {!multiple && (files?.id || files?.name) && (
            <div className="relative asset-component pointer" role="button">
              <AssetItem
                url={files?.preview || files?.url}
                type={files?.type}
                thumbnail
              />
              <button
                aria-label="delete"
                onClick={e => removeAsset(e, files.id || files.name)}
                type="button"
                className="absolute top-5 right-5 whiteBg error br8 p-1"
              >
                <MdDelete size="1.5rem" />
              </button>
            </div>
          )}
          <div {...getRootProps()} style={{ height: "min-content" }}>
            <input {...getInputProps()} />
            {(multiple || !(files?.id || files?.name)) && (
              <div
                className="asset-component br16 pointer"
                style={{ width: "131px", height: "131px" }}
              >
                <div className="black px18">
                  <img src={addImagePlaceholder} alt="upload asset" />
                </div>
              </div>
            )}
          </div>
        </aside>
      </section>
    </div>
  )
}

export default AssetsUploader
