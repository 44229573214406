import React from "react"

const OutLineArrowRight = ({ size, className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size ? size : "24"}
      height={size ? size : "24"}
      className={className}
      style={style}
      fill="currentColor"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
    </svg>
  )
}

export default OutLineArrowRight
