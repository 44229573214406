import EightsStep from "./eightsStep"
import FifthStep from "./fifthStep"
import FirstStep from "./firstStep"
import FourthStep from "./fourthStep"
import SecondStep from "./secondStep"
import SeventhStep from "./seventhStep"
import SixthStep from "./sixthStep"
import ThirdStep from "./thirdStep"

const ListPropertySteps = [
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  SixthStep,
  SeventhStep,
  EightsStep,
]

export default ListPropertySteps
