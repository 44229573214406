import instance from "./instance"

const profileApi = {
  updateProfile: data => instance.patch("me", data),
  getMyProperties: (params, offerType = "sale") =>
    instance.get(`properties?filter=offerType||\$eq||${offerType}`, {
      ...params,
    }),
  getMyFavorites: (params, offerType = "sale") =>
    instance.get(`me/favorites?type=${offerType}`, { ...params }),
  getMyFollowers: () => instance.get(`me/followers`),
  getMyNotifications: page =>
    instance.get(`me/notifications?page=${page ? page : "1"}`),
  getUserById: id => instance.get(`users/${id}`),
}

export default profileApi
