import React, { useContext, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import AssetsUploader from "../../assetsUploader"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"
import { FormLabel } from "../formInput"

const SixthStep = () => {
  const { values, errors, touched, setFieldValue, setCurrentStepInputs } =
    useContext(ListPropertyContext)

  const handleAssetsChange = (assets = []) => {
    setFieldValue("assets", assets)
  }

  useEffect(() => {
    setCurrentStepInputs(["assets"])
  }, [])

  return (
    <div className="res-w-3/4 h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="Images & Videos" />
      </FieldDesc>

      <FormLabel>
        <FormattedMessage defaultMessage="Upload your properties images & videos" />
      </FormLabel>
      <AssetsUploader
      className="assetsuploaderproperty"
        files={values.assets}
        setFiles={handleAssetsChange}
        multiple
        acceptVideo
        type="property"
      />
      {touched.assets && errors.assets?.length > 0 && (
        <div className="error mt-3">
          <FormattedMessage defaultMessage="Please upload all the assets before moving on" />
        </div>
      )}
    </div>
  )
}

export default SixthStep
