import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { setLoading } from "../../actions/settingsActions"
import ProfileComponent from "./profileComponent"
import profileApi from "../../_api/profile"
import { error } from "../../utils"
import dataApi from "../../_api/dataApi"
import { CondOperator } from "@nestjsx/crud-request"
import { navigate } from "gatsby-link"
import { myProfilePath } from "../../components/header"
import { FormattedMessage } from "react-intl"

const Profile = props => {
  const { id, currentUser, loading, setLoading } = props
  const [user, setUser] = useState()

  useEffect(() => {
    if (currentUser?.id === id) {
      navigate(myProfilePath)
    }
    if (id) {
      setLoading(true)
      profileApi
        .getUserById(id)
        .then(res => {
          res && setUser(res)
        })
        .catch(() => {
          error()
        })
        .finally(() => setLoading(false))
    }
  }, [id])

  const fetchMyProperties = (config = {}, offerType) => {
    const tempFilters = []
    if (id) {
      tempFilters.push({
        field: "user.id",
        operator: CondOperator.EQUALS,
        value: id,
      })
    }
    tempFilters.push({
      field: "offerType",
      operator: CondOperator.EQUALS,
      value: offerType,
    })

    if (!config?.page || config?.page === 1) {
      setLoading(true)
    }
    return dataApi
      .getProperties({
        ...config,
        filters: tempFilters,
      })
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(() => {
        error()
        return Promise.resolve(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <SEO title="My Profile" />
      {loading && <Loader />}
      <ProfileComponent
        user={user}
        fetchProperties={fetchMyProperties}
        propertiesTitle={<FormattedMessage defaultMessage="User Properties" />}
      />
    </Layout>
  )
}

const mapStateToProps = ({ shared: { loading }, user: { user } }) => ({
  loading,
  currentUser: user,
})
export default connect(mapStateToProps, {
  setLoading,
})(Profile)
