import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { FormattedMessage } from "react-intl"
import { useFormik } from "formik"
import StepperBar from "../stepperBar"
import Button from "../button"
import useStepper from "../hooksComponents/useStepper"
import ListServiceSteps from "./steps"
import ListServiceContext from "./serviceContext"
import listServiceValidationScheme from "./validationScheme"
import dataApi from "../../_api/dataApi"
import { error, success } from "../../utils"
import Seo from "../seo"
import { connect, useDispatch } from "react-redux"
import { openConfirmModal } from "../../actions/settingsActions"
import OutLineArrowLeft from "../../assets/icons/outLineArrowLeft"
import OutLineArrowRight from "../../assets/icons/outLineArrowRight"
import uniqueId from "lodash/uniqueId"
import { navigate } from "gatsby-link"
import axios from "axios"
import useMediaQuery from "../hooksComponents/useMediaQuery"

const ServiceForm = props => {
  const { user } = props
  const dispatch = useDispatch()
  const onCancelConfirm = () => {
    navigate(-1)
  }
  const onClickCancel = () => {
    dispatch(
      openConfirmModal({
        title: <FormattedMessage defaultMessage="Quit creating ad" />,
        msg: (
          <FormattedMessage defaultMessage="You will lose all the information you provided, do you want to cancel this Ad?" />
        ),
        onConfirm: onCancelConfirm,
      })
    )
  }
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery("(max-width: 548px)")
  const {
    goToNextStep,
    backToPrevStep,
    CurrentStepComponent,
    step,
    safeSetStep,
  } = useStepper(ListServiceSteps)

  const isLastStep = step === ListServiceSteps.length - 1

  const [currentStepInputs, setCurrentStepInputs] = useState([])

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    submitCount,
    setErrors,
    setTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      user: "",
      category: "",
      serviceCategory: "",
      first_name: "",
      last_name: "",
      email: "",
      show_phone: false,
      phone: "",
      description: "",
      address: "",
      logo: null,
      service_options: [{ title: "", price: "", id: uniqueId("service-") }],
      website_link: "",
      working_hours: [{ from: "", to: "" }],
      working_days: { Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0, Sun: 0 },
    },
    validationSchema: listServiceValidationScheme,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      if (!isLastStep) {
        setSubmitting(false)
        return
      }
      setLoading(true)
      const data = {
        ...values,
        logo: values.logo && values.logo.id ? values.logo.id : "",
        user: user && user.id,
      }
      dataApi
        .createService(data)
        .then(res => {
          success(
            <FormattedMessage defaultMessage="Service ad was created successfully." />
          )
          resetForm()
          safeSetStep(0)
          navigate(`/app/service/${res?.id}`)
        })
        .catch(err => {
          console.log(err)
          if (err && err.message && err.message.length > 0) {
            err.message.map(async errMsg => {
              if (errMsg === "phone must be a valid phone number") {
                error("Please enter a valid phone number")
                await setCurrentStepInputs([
                  "first_name",
                  "last_name",
                  "phone",
                  "show_phone",
                  "email",
                ])
                await setFieldValue("phone", "")
                await safeSetStep(0)
              } else {
                error(errMsg)
              }
            })
          }
        })
        .finally(() => {
          setLoading(false)
          setSubmitting(false)
        })

      // const postData = (e) => {
      //   e.preventDefault();
      //   axios.post(''. {
      //     title: "",
      //     user: "",
      //     category: "",
      //     first_name: "",
      //     last_name: "",
      //     email: "",
      //     show_phone: false,
      //     phone: "",
      //     description: "",
      //     address: "",
      //     logo: "",
      //     service_options: [],
      //     website_link: "",
      //     working_hours: [],
      //     working_days: { "Mon": 0, "Tue": 0, "Wed": 0, "Thu": 0, "Fri": 0, "Sat": 0, "Sun": 0 }
      //   }).then(res => console.log('posting data', res)).catch(err => console.log(err))
      // }
    },
  })
  useEffect(() => {
    if (!isSubmitting && !isLastStep && submitCount > 0) {
      let isCurrentStepInvalid = false
      currentStepInputs.forEach(input => {
        if (errors[input]) {
          isCurrentStepInvalid = true
        }
      })
      if (step === 1) {
        if (
          values.working_days.Mon === 0 &&
          values.working_days.Tue === 0 &&
          values.working_days.Wed === 0 &&
          values.working_days.Thu === 0 &&
          values.working_days.Fri === 0 &&
          values.working_days.Sat === 0 &&
          values.working_days.Sun === 0
        ) {
          isCurrentStepInvalid = true
          setErrors({
            ...errors,
            working_days: "Please select at least one working day",
          })
        }
      }
      if (!isCurrentStepInvalid) {
        setErrors({})
        setTouched({})
        goToNextStep()
      }
    }
  }, [isSubmitting])

  return (
    <ListServiceContext.Provider
      value={{
        values,
        setValues,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setCurrentStepInputs,
      }}
    >
      <Seo title="List Service Ad" />
      <div
        className="list-property d-flex flex-column"
        style={{ height: "50%" }}
      >
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mb-3">
          <button onClick={() => navigate("/")}>
            <h1
              className="brand-text d-none d-lg-block"
              style={{
                fontSize: "36px",
                fontWeight: "900",
                lineHeight: "48px",
              }}
            >
              <FormattedMessage defaultMessage="SIMSAR" />
            </h1>
          </button>
          <button
            className="px-0 px16 primary font-bold"
            style={{ fontWeight: "700" }}
            onClick={onClickCancel}
          >
            <FormattedMessage defaultMessage="Cancel & Close" />
            <AiOutlineClose fontSize="16" className="ms-2" />
          </button>
        </div>
        <div className="w-100 d-flex flex-column flex-lg-row justify-content-between">
          <h2 className="px32 font-bold black text-decorated">
            <FormattedMessage defaultMessage="List a Service" />
          </h2>
          <p className="px20 darkGrey">
            <FormattedMessage defaultMessage="If you offer services for house owners we will be more than happy to list your services in our website" />
          </p>
        </div>

        <div className="w-100 mt-3 pt-3 flex-grow-1 d-flex flex-column overflow-hidden">
          <StepperBar stepsCount={ListServiceSteps.length} currentStep={step} />
          <form
            className="pt-5 d-flex flex-column flex-grow-1 overflow-hidden relative"
            onSubmit={handleSubmit}
            noValidate
          >
            <div
              className={`w-100 px-1 flex-grow-1 d-flex flex-column justify-content-center align-items-center scrollable ${
                isLastStep && isMobile && "pb-5"
              }`}
            >
              <CurrentStepComponent />
            </div>
            {isLastStep ? (
              <div className="d-flex flex-wrap darkGrey px16 byclickingservice">
                By clicking 'Agree' you confirm that you accept Simsar
                <a
                  href="/terms"
                  className="linkColor font-bold linkHover pointer"
                >
                  terms and conditions.
                </a>
              </div>
            ) : null}
            <div
              className="w-100 pb-3 d-flex justify-content-between justify-content-md-end space-x-20 align-items-center"
              style={{ marginTop: isLastStep && isMobile ? "40px" : "11px" }}
            >
              <Button
                className="backbtnservice"
                disabled={step === 0}
                type="button"
                color="white"
                onClick={backToPrevStep}
              >
                <OutLineArrowLeft className="me-3 localized" />
                <FormattedMessage defaultMessage="Back" />
              </Button>

              <Button
                style={{
                  width: "217px",
                  height: "58px",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                type="submit"
                color="secondary"
                loading={loading}
              >
                {isLastStep ? (
                  <FormattedMessage defaultMessage="Agree & Create Ad" />
                ) : (
                  <FormattedMessage defaultMessage="Next" />
                )}
                <OutLineArrowRight className="ms-3 localized" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </ListServiceContext.Provider>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ user })
export default connect(mapStateToProps, {})(ServiceForm)
