import React from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { setLoading } from "../../actions/settingsActions"
import ProfileComponent from "./profileComponent"
import profileApi from "../../_api/profile"
import { error } from "../../utils"
import { FormattedMessage } from "react-intl"

const MyProfile = props => {
  const { loading, user, propertyFilters, setLoading } = props

  const fetchMyProperties = (config = {}, offerType) => {
    if (!config?.page || config?.page === 1) {
      setLoading(true)
    }
    return profileApi
      .getMyProperties(config, offerType)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(() => {
        error()
        return Promise.resolve(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <SEO title="My Profile" />
      {loading && <Loader />}
      <ProfileComponent
        user={user}
        fetchProperties={fetchMyProperties}
        propertiesTitle={<FormattedMessage defaultMessage="My Properties" />}
      />
    </Layout>
  )
}

const mapStateToProps = ({ user: { user }, shared: { loading } }) => ({
  user,
  loading,
})
export default connect(mapStateToProps, {
  setLoading,
})(MyProfile)
