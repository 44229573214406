import React from "react"

const PlayIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0002 64.1668C51.1085 64.1668 64.1668 51.1085 64.1668 35.0002C64.1668 18.8919 51.1085 5.8335 35.0002 5.8335C18.8919 5.8335 5.8335 18.8919 5.8335 35.0002C5.8335 51.1085 18.8919 64.1668 35.0002 64.1668Z"
        fill="white"
        stroke="#3BBA9C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1665 23.3335L46.6665 35.0002L29.1665 46.6668V23.3335Z"
        fill="#3BBA9C"
      />
    </svg>
  )
}

export default PlayIcon
