import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { FormattedMessage } from "react-intl"
import { useFormik } from "formik"
import StepperBar from "../stepperBar"
import Button from "../button"
import useStepper from "../hooksComponents/useStepper"
import ListPropertySteps from "./steps"
import ListPropertyContext from "./propertyContext"
import listPropertyValidationScheme from "./validationScheme"
import dataApi from "../../_api/dataApi"
import { error, success } from "../../utils"
import Seo from "../seo"
import { useDispatch } from "react-redux"
import { openConfirmModal } from "../../actions/settingsActions"
import OutLineArrowLeft from "../../assets/icons/outLineArrowLeft"
import OutLineArrowRight from "../../assets/icons/outLineArrowRight"
import { navigate } from "gatsby-link"
import usePropertyForm from "./usePropertyForm"

const PropertyForm = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onCancelConfirm = () => {
    navigate(-1)
  }
  const onClickCancel = () => {
    dispatch(
      openConfirmModal({
        title: <FormattedMessage defaultMessage="Quit creating ad" />,
        msg: (
          <FormattedMessage defaultMessage="You will lose all the information you provided, do you want to cancel this Ad?" />
        ),
        onConfirm: onCancelConfirm,
      })
    )
  }

  const handleCreateProperty = (values, { setSubmitting }) => {
    if (!isLastStep) {
      setSubmitting(false)
      return
    }
    setLoading(true)
    dataApi
      .createProperty(values)
      .then(res => {
        if (res?.id) {
          success(
            <FormattedMessage defaultMessage="Property ad was created successfully, you will get a notification when it's approved " />
          )
          navigate(`/app/property/${res?.id}`)
        }
      })
      .catch(err => {
        error()
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  const formContext = usePropertyForm(handleCreateProperty, ListPropertySteps)

  const {
    goToNextStep,
    backToPrevStep,
    CurrentStepComponent,
    step,
    currentStepInputs,
    errors,
    handleSubmit,
    isSubmitting,
    submitCount,
    setErrors,
    setTouched,
  } = formContext

  const isLastStep = step === ListPropertySteps.length - 1

  useEffect(() => {
    if (!isSubmitting && !isLastStep && submitCount > 0) {
      let isCurrentStepInvalid = false
      currentStepInputs.forEach(input => {
        if (errors[input]) {
          isCurrentStepInvalid = true
        }
      })

      if (!isCurrentStepInvalid) {
        setErrors({})
        setTouched({})
        goToNextStep()
      }
    }
  }, [isSubmitting])

  return (
    <ListPropertyContext.Provider value={formContext}>
      <Seo title="List Property Ad" />
      <div style={{ backgroundColor: "#F4F8FF" }}>
        <div className="list-property d-flex flex-column">
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-between mb-3">
            <button onClick={() => navigate("/")}>
              <h1 className="brand-text d-none d-lg-block">
                <FormattedMessage defaultMessage="SIMSAR" />
              </h1>
            </button>
            <button
              className="px-0 px16 primary font-bold"
              onClick={onClickCancel}
            >
              <FormattedMessage defaultMessage="Cancel & Close" />
              <AiOutlineClose fontSize="16" className="ms-2" />
            </button>
          </div>
          <div className="w-100 d-flex flex-column flex-lg-row justify-content-between">
            <h2 className="px32 font-bold black text-decorated">
              <FormattedMessage defaultMessage="List a property for free" />
            </h2>
            <p className="px20 darkGrey">
              <FormattedMessage defaultMessage="If you have a house for sell or rent you can post a free ad on our website" />
            </p>
          </div>
          <div
            className=" mt-3 pt-3 br16"
            style={{
              height: "700px",
              marginBottom: "50px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div className="w-100 mt-3 pt-3 h-100 d-flex flex-column overflow-hidden br16">
              <StepperBar
                stepsCount={ListPropertySteps.length}
                currentStep={step}
              />
              <form
                className="pt-5 d-flex flex-column flex-grow-1 overflow-hidden relative"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="w-100 px-1 flex-grow-1 d-flex flex-column justify-content-center align-items-center scrollable">
                  <CurrentStepComponent />
                </div>
                {isLastStep ? (
                  <div className="darkGrey px16 termsproperty">
                    By clicking 'Agree' you confirm that you accept Simsar
                    <a
                      href="/terms"
                      style={{ marginLeft: 5 }}
                      className="linkColor font-bold linkHover pointer"
                    >
                      terms and conditions.
                    </a>
                  </div>
                ) : null}
                <div
                  className="w-100 pb-4 d-flex justify-content-md-end align-items-center"
                  style={{ gap: "15px", marginTop: "11px" }}
                >
                  <Button
                    className="backbtnproperty"
                    disabled={step === 0}
                    type="button"
                    color="white"
                    onClick={backToPrevStep}
                  >
                    <OutLineArrowLeft className="me-3 localized" />
                    <FormattedMessage defaultMessage="Back" />
                  </Button>

                  <Button
                    // disabled={step === 0}
                    className="agreebtnproperty"
                    type="submit"
                    color="secondary"
                    loading={loading}
                  >
                    {isLastStep ? (
                      <FormattedMessage defaultMessage="Agree & Submit Ad" />
                    ) : (
                      <FormattedMessage defaultMessage="Next" />
                    )}
                    <OutLineArrowRight className="ms-3 localized" />
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ListPropertyContext.Provider>
  )
}

export default PropertyForm
