import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Loader from "../../components/loader"
import Layout from "../../components/layout"
import { connect, useDispatch } from "react-redux"
import _ from "lodash"
import defaultImage from "../../assets/images/noData.png"
import useForm from "../../components/hooksComponents/useForm"
import api from "../../_api/profile"
import {
  getMyFollowers,
  getMyNotifications,
} from "../../actions/profileActions"
import { FormattedMessage } from "react-intl"
import { navigate } from "@reach/router"
import ArrowLeft from "../../assets/icons/arrowLeft"
import { myProfilePath } from "../../components/header"
import { getNotificationData } from "../../components/propertyComponent/utils"
import CloseIcon from "../../assets/icons/closeIcon"
import dataApi from "../../_api/dataApi"

const MyNotifications = props => {
  const { loading, user, myNotifications } = props
  const [notifications, setNotification] = useState([])
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const { state, setState } = useForm({})

  useEffect(() => {
    if (user && !loading) {
      setState(user)
    }
  }, [user])
  const getNotifications = async () => {
    await dispatch(getMyNotifications(page)).then(res => {
      if (res && res.data) {
        setNotification([
          ...notifications.filter(item => !item.isRead),
          ...res.data.filter(item => !item.isRead),
        ])
      }
    })
  }
  const markAsReadNotf = async id => {
    let formData = new FormData()
    formData.append("notification_ids[0]", id)
    await dataApi.readNotification(formData).then(async res => {
      if (res) {
        await dataApi.getSingleNotification(id).then(res => {
          let modifiedNotf = notifications
          let notfIndex = modifiedNotf.findIndex(item => item.id === id)
          modifiedNotf[notfIndex] = res
          setNotification(modifiedNotf.filter(item => !item.isRead))
        })
      }
    })
  }
  const loadMore = async () => {
    setPage(page + 1)
  }

  useEffect(() => {
    getNotifications(page)
  }, [page])

  let statues = {
    approved: "border-secondary",
    added: "border-secondary",
    rejected: "border-error",
  }

  return (
    <Layout>
      <SEO title="My Notifications" />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="px-10rem">
          <div className="py-5">
            <div className="signInBorder d-flex align-items-center justify-content-between">
              <span className="font-black black mx-3 mynottitle">
                <FormattedMessage defaultMessage="My Notifications" />
              </span>
              <button
                className="w-15 justify-content-between d-flex align-items-center primary font-bold button-shadow px-3 py-2 br8 button-primaryBg custom-border-primary gotomyprofilebtn"
                onClick={() => navigate(myProfilePath)}
              >
                <div>
                  <ArrowLeft size="1.5rem" />
                </div>
                <div className="d-lg-flex d-none px-16">
                  <FormattedMessage defaultMessage="Go to my profile" />
                </div>
              </button>
            </div>
          </div>
          {!_.isEmpty(notifications) ? (
            <div className="d-flex align-items-center flex-column py-5">
              <div
                className="whiteBg w-100 py-3 px-5 br8"
                style={{
                  maxWidth: "1113px",
                  maxHeight: "450px",
                  overflowY: "scroll",
                }}
              >
                {notifications.map((data, index) => {
                  const notificationData = getNotificationData(
                    data.category,
                    data.senderId && data.senderId.name
                      ? data.senderId.name
                      : "Null"
                  )
                  if (data && data.senderId && !data.isRead)
                    return (
                      <div key={index} className="my-notification">
                        <div
                          className={`notifications-border ${statues["approved"]}`}
                        >
                          <div className="black font-bold px20 m-3">
                            {notificationData.title}
                          </div>
                          <div
                            onClick={() =>
                              data.notifableId !== user.id
                                ? navigate("/app/profile/" + data.notifableId)
                                : null
                            }
                            className="darkGrey px20 m-3 pointer"
                          >
                            {notificationData.description}
                          </div>
                        </div>
                        <hr className="border-grey" />
                        <button
                          onClick={() => markAsReadNotf(data.id)}
                          className="relative"
                        >
                          <CloseIcon
                            style={{
                              width: 21,
                              height: 21,
                              position: "absolute",
                            }}
                          />
                        </button>
                      </div>
                    )
                })}
                {page < myNotifications.pageCount ? (
                  <button onClick={loadMore} style={{ color: "#3bba9c" }}>
                    Load More
                  </button>
                ) : null}
              </div>
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center justify-content-center flex-column py-5">
                <img alt={"noData"} src={defaultImage} className="w-sm-100" />
                <div className="my-5">
                  <div className="signInBorder">
                    <div className="text-start font-semi-bold black px36 mx-3">
                      <FormattedMessage defaultMessage="Nothing to show here" />
                    </div>
                    <div className="text-start black px20 mx-3">
                      <FormattedMessage defaultMessage="There are no notifications" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-20vh" />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({
  user: { user, myNotifications },
  shared: { loading },
}) => {
  return { user, loading, myNotifications }
}
export default connect(mapStateToProps, { getMyFollowers, getMyNotifications })(
  MyNotifications
)
