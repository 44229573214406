import React from "react"
import { FormattedMessage } from "react-intl"
import * as yup from "yup"

const REQUIRED_ERR_MSG = <FormattedMessage defaultMessage="Required" />

const listPropertyValidationScheme = yup.object().shape({
  title: yup.string().required(REQUIRED_ERR_MSG),
  offerType: yup.string().required(REQUIRED_ERR_MSG).oneOf(["rent", "sale"]),
  relationship: yup
    .string()
    .required(REQUIRED_ERR_MSG)
    .oneOf(["owner", "broker"]),
  price: yup.number().required(REQUIRED_ERR_MSG),
  size: yup
    .number()
    .required(REQUIRED_ERR_MSG)
    .min(1, <FormattedMessage defaultMessage="Must be greater than 0" />),
  rentCheck: yup
    .string()
    .nullable()
    .when("offerType", {
      is: "rent",
      then: yup
        .string()
        .required(REQUIRED_ERR_MSG)
        .typeError(REQUIRED_ERR_MSG)
        .oneOf(["month", "year", "sixMonth"]),
    }),
  address: yup.string().required(REQUIRED_ERR_MSG),
  buildingNumber: yup.string().required(REQUIRED_ERR_MSG).default(""),
  neighborhood: yup.string().required(REQUIRED_ERR_MSG).default(""),
  description: yup.string(),
  isWithFurniture: yup.bool().required(),
  isConstructionCompleted: yup.bool().required(),
  bedRoomsCount: yup.number().required(REQUIRED_ERR_MSG),
  bathsCount: yup.number().required(REQUIRED_ERR_MSG),
  sizeUnit: yup.object({}).required(REQUIRED_ERR_MSG),
  type: yup
    .object({})
    .shape({
      id: yup.string().required(REQUIRED_ERR_MSG),
    })
    .required(REQUIRED_ERR_MSG),
  //   project: {},
  assets: yup.array().of(
    yup.object().shape({
      id: yup
        .string()
        .required(
          <FormattedMessage defaultMessage="Please upload your assets first before moving on" />
        ),
    })
  ),
  //   amenities: [],
})

export default listPropertyValidationScheme
