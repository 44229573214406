import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { connect } from "react-redux"
import Loader from "../components/loader"
import project from "../assets/images/projectCover.png"
import building from "../assets/images/building.png"
import company from "../assets/images/company.png"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon"
import { FavoriteIcon } from "../assets/images/propertiesImages/favoriteIcon"
import { Date } from "../assets/images/projectsIcons/date"
import { House } from "../assets/images/projectsIcons/house"
import { MapPin } from "../assets/images/projectsIcons/map"
import { Payment } from "../assets/images/projectsIcons/payment"
import { SwimmingPool } from "../assets/images/projectsIcons/pool"
import { HorizontalIcon } from "../assets/images/horizontal"
import { VerticalIcon } from "../assets/images/vertical"
import { Type } from "../assets/images/projectsIcons/type"
import { agentsCards, propertiesCards } from "../constants/headerItems"
import PropertyComponent from "../components/propertyComponent"
import AgentComponent from "../components/agentComponent"
import { toggleCustomModal } from "../actions/settingsActions"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import { property } from "lodash"

const Projects = props => {
  const { entityData, loading } = props
  const [viewMood, setViewMood] = useState("horizontal")
  const showInMapClicked = (latitude, longitude) => {
    window.open("https://maps.google.com?q=" + latitude + "," + longitude)
  }
  return (
    <Layout>
      <SEO
        title={entityData?.name || "Project"}
        image={entityData?.image && entityData?.image}
        description={entityData?.description}
      />
      {loading && <Loader />}
      <div className="greyXLightBg">
        <div className="p-5">
          <div className="row">
            <div className="col-lg-9 col-12 whiteBg br8">
              <div className="py-3 px-2">
                <img src={project} alt="project" className="w-100" />
                <div className="m-lg-3 m-3" style={{ paddingTop: "40px" }}>
                  <div className="signInBorder d-flex align-items-center justify-content-between">
                    <span className="font-black black mx-3 bagdadNewLineText">
                      Bagdad New Line
                    </span>
                    <div>
                      <button
                        className="mx-2" /*onClick={() => addToFavoriteHandler(productData.id)}*/
                      >
                        <ShareIcon />
                      </button>
                      <button /*onClick={() => addToFavoriteHandler(productData.id)}*/
                      >
                        <FavoriteIcon />
                      </button>
                    </div>
                  </div>

                  <div className="row my-4">
                    <div className="col-lg-7 col-12 mb-3">
                      <div className="secondaryLightBg px-3 py-2 br8 d-flex align-items-center justify-content-between">
                        <span className="secondary startingPrice">
                          Starting Price per Apartment{" "}
                        </span>
                        <span className="font-bold secondary sp450">
                          {" "}
                          SP 450,000,000
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-12 mb-3">
                      <div className="errorLightBg px-3 py-2 br8 d-flex align-items-center justify-content-between">
                        <span className="error startingPrice">
                          <FormattedMessage defaultMessage="Price per meter" />
                        </span>
                        <span className="font-bold error sp450">
                          SP 10,000,000
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="d-flex align-items-center mb-4">
                        <MapPin />
                        <span className="darkGrey mx-2 px20">
                          Damascus, Bagdad Street, Next to Al Zain
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <Date />
                        <span className="darkGrey mx-2 px20">
                          Completion date: 30 June 2023
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <House />
                        <span className="darkGrey mx-2 px20">
                          Realestates: 159
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <Type />
                        <span className="darkGrey mx-2 px20">
                          Properties Type: Apartments, villas, shops, offices
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="d-flex align-items-center mb-4">
                        <Payment />
                        <span className="darkGrey mx-2 px20">
                          Payment: Cash • Credite card • installments
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <SwimmingPool />
                        <span className="darkGrey mx-2 px20">
                          Amenties: Carage • Swimming pool • Garden
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <MapPin />
                        <span className="darkGrey mx-2 px20">
                          Near by places: Hadara School • Mashfa Al Hayat
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="darkGrey font-bold px26 mb-4">
                    <FormattedMessage defaultMessage="Description" />
                  </div>
                  <div className="darkGrey px20">
                    <p>
                      Located on the crescent of The Palm and next to the iconic
                      Atlantis resort, this new generation of distinctive luxury
                      will rise at 185 meters high, double the height of
                      Atlantis The Palm, to offer a sophisticated lifestyle
                      experience encapsulated in dramatic architecture.
                    </p>
                    <p>
                      The Royal Atlantis Residences will consist of 46-storeys
                      offering a selection of two, three, four and five-bedroom
                      homes, Sky Courts, Penthouses and Garden Suites. When
                      completed, The Royal Atlantis Resort and Residences will
                      offer 232 residences and 780 new lavish guest rooms and
                      suites in the resort, with areas starting from 130 to 1500
                      square meters, on over 10 hectares of land. And to enrich
                      the exclusivity, it offers 82 unique floor-plans and 94
                      swimming pools. With prices starting at approximately AED
                      7,000,000, The Royal Atlantis Residences will consist of
                      contemporary living spaces with uninterrupted views of the
                      ocean or across the waters of The Palm towards the Dubai
                      city skyline.
                    </p>
                    <p>
                      This stunning address features a breathtaking composition
                      that grazes the Dubai Skyline, set amongst a throbbing
                      community. The world’s leading designers, architects, and
                      artists have carefully crafted these extravagance homes,
                      awaiting your arrival. Enjoy the most elite address, a
                      property in Dubai, unlike any other in the world. Rising
                      above the crystal clear waters of the Arabian Gulf, The
                      Royal Atlantis Residences are amongst the most exclusive
                      beach homes and apartments in Dubai. Each home includes
                      the height of interior design, creating a haven of space
                      and joy for every resident, indulging the residents and
                      guest with a wide array of top-notch dining, leisure,
                      entertainment, and recreational facilities, furthermore,
                      they will have access to Atlantis The Palm amenities and
                      services.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-12 whiteBg br8 fixed-content overflow-auto my-5 my-lg-0 z-100">
              <div className="p-1 p-lg-3">
                <div
                  className="d-flex align-items-center flex-wrap"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <img
                    src={building}
                    alt="buildings"
                    width="auto"
                    height="100%"
                    className="mb-4 me-4"
                  />
                  <div style={{ fontSize: "20px", fontWeight: "400" }}>
                    Company <br /> <b> Buildings</b>
                  </div>
                </div>
                <button
                  onClick={() => showInMapClicked(Projects.lat, Projects.long)}
                >
                  <div className="primary font-bold px16 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center">
                    <span className="me-3">
                      <FormattedMessage defaultMessage="View location on map" />
                    </span>
                    <ArrowRight size="1.5rem" />
                  </div>
                </button>
                <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break">
                  +963 999 999 9999
                </div>
                <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break">
                  Ahmed@gmail.com
                </div>
                <div className="greyLightBg primary font-bold px16 p-3 br8 mb-4 w-100 word-break">
                  www.buildings.com
                </div>
                <div className="darkGrey px16 p-3 br8">
                  <FormattedMessage defaultMessage="Never wire money or financial info to a seller on the internet. For your security, all transactions should be done in person." />
                  <div
                    className="linkColor font-bold linkHover pointer"
                    onClick={() =>
                      props.toggleCustomModal({
                        customOpen: true,
                        modalType: "report",
                        title: "Report abuse",
                      })
                    }
                  >
                    <FormattedMessage defaultMessage="Report Abuse" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-5">
          <div className="row">
            <div className="col-lg-9 col-md-10 col-12">
              <div className="signInBorder d-flex align-items-center justify-content-between mb-4">
                <span className="font-semi-bold black px36 mx-3">
                  <FormattedMessage defaultMessage="Project Agents" />
                </span>
              </div>
              <div className="row">
                {agentsCards.map((agent, index) => (
                  <AgentComponent key={index} agentData={agent} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-5">
          <div className="row">
            <div className="col-lg-9 col-md-10 col-12">
              <div className="signInBorder d-flex align-items-center justify-content-between mb-4">
                <span className="font-semi-bold black px36 mx-3">
                  <FormattedMessage defaultMessage="Project properties" />
                </span>
                <button className="primary font-bold button-shadow p-2 br8 whiteBg d-flex align-items-center justify-content-between">
                  <button
                    className="mx-2"
                    onClick={() => setViewMood("horizontal")}
                  >
                    <HorizontalIcon
                      color={viewMood === "horizontal" && "#3D3B4A"}
                    />
                  </button>
                  <button
                    className="mx-2"
                    onClick={() => setViewMood("vertical")}
                  >
                    <VerticalIcon
                      color={viewMood === "vertical" && "#3D3B4A"}
                    />
                  </button>
                </button>
              </div>
              <div className="row pb-5" style={{ width: "102.6%" }}>
                {propertiesCards.map((property, index) => (
                  <PropertyComponent
                    key={index}
                    propertyData={property}
                    mode={viewMood}
                  />
                ))}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <button className="primary font-bold button-shadow px-3 py-2 br8 button-secondaryBorder">
                  <span className="me-3">
                    <FormattedMessage defaultMessage="View all offers" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

// const mapStateToProps = ({ userReducer: { user }, data: { loading, product, cart }, settings: {lang}}) => ({ user, loading, product, cart, lang });
export default connect(null, { toggleCustomModal })(Projects)
