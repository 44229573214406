import React, { useState } from "react"

const useStepper = (stepsArr = []) => {
  const [step, setStep] = useState(0)

  const safeSetStep = step => {
    if (
      typeof step === "number" &&
      (step * 10) % 10 === 0 &&
      step >= 0 &&
      step < stepsArr.length
    ) {
      setStep(step)
    }
  }

  const goToNextStep = () => {
    setStep(prev => {
      if (prev + 1 >= stepsArr.length) {
        return prev
      }
      return prev + 1
    })
  }
  const backToPrevStep = () => {
    setStep(prev => {
      if (prev - 1 < 0) {
        return prev
      }
      return prev - 1
    })
  }

  const CurrentStepComponent = stepsArr[step] ?? (() => <></>)

  return {
    step,
    safeSetStep,
    goToNextStep,
    backToPrevStep,
    CurrentStepComponent,
  }
}

export default useStepper
