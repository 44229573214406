import { connect } from "react-redux"
import SEO from "../components/seo"
import Layout from "../components/layout"
import React, { useCallback, useEffect, useState } from "react"
import MapComponent from "../components/mapComponent"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import ArrowLeft from "../assets/icons/arrowLeft"
import {
  clearPropertyFilters,
  openGeneralModal,
  setGeneralModal,
  setLoading,
} from "../actions/settingsActions"
import AliceCarousel from "react-alice-carousel"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon"
import { FavoriteIcon } from "../assets/images/propertiesImages/favoriteIcon"
import { BedIcon } from "../assets/images/propertiesImages/bedIcon"
import { BathIcon } from "../assets/images/propertiesImages/bathIcon"
import { SizeIcon } from "../assets/images/propertiesImages/sizeIcon"
import { navigate } from "gatsby"
import details from "../assets/images/propertiesImages/details.png"
import details2 from "../assets/images/propertiesImages/details2.png"
import ChevRight from "../assets/icons/chevRight"
import defaultImg from "../assets/images/placeholder1.svg"
import { propertiesCards } from "../constants/headerItems"
import HeaderDropDownComponent from "../components/headerComponents/headerDropDown"
import { getProperties } from "../actions/propertiesAction"
import usePropertyFilters from "../components/hooksComponents/usePropertyFilters"
import dataApi from "../_api/dataApi"
import { error } from "../utils"
import ActionsButtons from "../components/propertyComponent/actionsButtons"
import { Box } from "@material-ui/core"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"

const MapFinder = ({
  setGeneralModal,
  loading,
  clearPropertyFilters,
  lang,
  sort,
  getProperties,
  setLoading,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dropDownItems, setDropDownItems] = useState([])
  const [propertiesData, setPropertiesData] = useState(null)
  const [properties, setProperties] = useState([])
  const [total, setTotal] = useState(0)
  const [latLong, setLatLong] = useState(null)

  const dropItems = [
    { page: <FormattedMessage defaultMessage="Price: highest to lowest" /> },
    { page: <FormattedMessage defaultMessage="Price: lowest to highest" /> },
    { page: <FormattedMessage defaultMessage="Newest to oldest" /> },
    { page: <FormattedMessage defaultMessage="Oldest to newest" /> },
  ]
  const apiFilters = usePropertyFilters()
  const isMobile = useMediaQuery("(max-width: 528px)")
  const isTab = useMediaQuery("(max-width: 991px)")
  const isMD = useMediaQuery("(max-width: 1500px)")
  const fetchProperties = useCallback(
    (config = {}) => {
      if (!config?.page || config?.page === 1) {
        setLoading(true)
      }
      return dataApi
        .getProperties({ ...config, filters: apiFilters, sort })
        .then(res => {
          setTotal(res?.total || 0)
          return Promise.resolve(res)
        })
        .catch(() => {
          error()
          return Promise.resolve(null)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [apiFilters, sort, getProperties, setLoading]
  )
  useEffect(async () => {
    const properties = await fetchProperties()
    setPropertiesData(properties)
    if (properties.data) {
      let formatedProperties = []
      await properties.data.forEach(element => {
        if (element && element.lat && element.long) {
          formatedProperties.push(element)
        }
      })
      if (formatedProperties && formatedProperties.length > 0) {
        setLatLong({
          id: formatedProperties[0].id,
          lat: formatedProperties[0].lat,
          long: formatedProperties[0].long,
        })
        setProperties(formatedProperties)
        console.log("Properties", formatedProperties)
      } else {
        setLatLong({ id: null, lat: 24.4539, long: 54.3773 })
      }
    }
  }, [])
  console.log("latlong", latLong)

  const locations = [
    {
      id: 1,
      name: "Chicago, Illinois",
      lat: 41.881832,
      lng: -87.623177,
    },
    {
      id: 2,
      name: "Denver, Colorado",
      lat: 39.739235,
      lng: -104.99025,
    },
    {
      id: 3,
      name: "Los Angeles, California",
      lat: 34.052235,
      lng: -118.243683,
    },
    {
      id: 4,
      name: "New York, New York",
      lat: 40.712776,
      lng: -74.005974,
    },
  ]

  const items = [details, details2, details, details2, details2]
  const thumbItems = (items, setImage) => {
    return items.map((item, i) => (
      <div className="px-2">
        <img
          src={item.url}
          key={i}
          alt={`thumb-${i}`}
          style={{ width: "100%", height: 117, objectFit: "cover" }}
          className="br8 pointer"
          onClick={() => setImage(item.url)}
        />
      </div>
    ))
  }

  const [currentImage, setCurrentImage] = useState()
  // const [thumbs] = useState(thumbItems(items, setCurrentImage))

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 4,
    },
  }

  const navigateToProperty = id => {
    setGeneralModal({ open: false })
    navigate(`/app/property/${id}`)
  }

  const renderMapDetails = details => {
    const images = thumbItems(details.assets, setCurrentImage)
    return (
      <div className="row p-3 justify-content-between">
        <div className="col-lg-5 col-12 overflow-scroll hideScrollBar h-90vh">
          <div className="w-100">
            <Box style={{ width: "100%" }}>
              {images && images.length > 0 ? (
                <div className="d-lg-none d-block">
                  <img
                    style={{
                      height: "350px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={details.assets[0].url}
                    alt={"property"}
                    className="br8"
                  />
                  <div className="property-details-carousel my-5">
                    <AliceCarousel
                      responsive={responsive}
                      disableDotsControls
                      disableButtonsControls
                      items={images}
                      mouseTracking
                      touchMoveDefaultEvents={false}
                      autoPlay={true}
                      infinite={true}
                      autoPlayInterval={700}
                    />
                  </div>
                </div>
              ) : null}
            </Box>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="black px20 mx-3">For sale • From broker</span>
              <ActionsButtons property={details} />
              {/* <div>
              <button
                className="mx-2" onClick={() => addToFavoriteHandler(productData.id)}
              >
                <ShareIcon />
              </button>
              <button onClick={() => addToFavoriteHandler(productData.id)}>
                <FavoriteIcon />
              </button>
            </div> */}
            </div>
            <div className="secondaryLightBg py-2 br16 mb-4 w-50">
              <button className="font-bold secondary">
                <span className="px20 mx-2">SP {details.price}</span>
              </button>
            </div>
            <div className="mb-4 darkGrey px18">
              GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED
              36,500 )
            </div>
            <div
              style={{ gap: 30 }}
              className="d-flex flex-wrap justify-content-start  align-items-center mb-4"
            >
              {details && details.bedRoomsCount && (
                <span className="darkGrey px18 d-flex align-items-center">
                  <BedIcon />
                  <span className="mx-2 text-nowrap">
                    {details && details.bedRoomsCount} bed{" "}
                    {details &&
                    details.bedRoomsCount &&
                    details.bedRoomsCount === 1
                      ? "room"
                      : "rooms"}
                  </span>
                </span>
              )}
              {details && details.bathsCount && (
                <span className="darkGrey px18 d-flex align-items-center">
                  <BathIcon />
                  <span className="mx-2 text-nowrap">
                    {details && details.bathsCount}{" "}
                    {details && details.bathsCount && details.bathsCount === 1
                      ? "bath"
                      : "baths"}
                  </span>
                </span>
              )}
              <span className="darkGrey px18 d-flex align-items-center">
                <SizeIcon />
                <span className="mx-2 text-nowrap">
                  {details && details.size}{" "}
                  {details && details.sizeUnit && details.sizeUnit.title} in
                  size
                </span>
              </span>
            </div>
            <div className="mb-4 darkGrey px18">
              {details && details.address}
            </div>
            <div className="mb-4 font-bold px20 darkGrey">More details</div>
            <div className="mb-4 px18 darkGrey">
              {details && details.isWithFurniture ? "Furnitured • " : null}{" "}
              {details && details.isContructionCompleted
                ? "Construction complete • "
                : null}
              {details &&
                details.amenities &&
                details.amenities.length > 0 &&
                details.amenities.map((amenity, index) => {
                  return amenity.titleEn + index < details.amenities.length - 1
                    ? " • "
                    : ""
                })}
            </div>
            <div
              className={`d-flex flex-row ${
                isTab ? "position-relative" : "position-absolute"
              }`}
              style={{ bottom: 25, gap: 24, marginTop: 40 }}
            >
              <button
                className="mx-1 justify-content-between d-flex align-items-center primary font-bold p-3 br8 button-primaryBg custom-border-primary pointer"
                onClick={() => setGeneralModal({ open: false })}
              >
                <ArrowLeft size="1.5rem" />
                {isMD ? (
                  <span className="ms-3">
                    <FormattedMessage defaultMessage="Back" />
                  </span>
                ) : (
                  <span className="ms-3">
                    <FormattedMessage defaultMessage="Back to map finder" />
                  </span>
                )}
              </button>
              <button
                onClick={() => navigateToProperty(details.id)}
                className="mx-1 white secondaryBg font-bold px16 p-3 br8 pointer 100 d-flex justify-content-between align-items-center button-secondaryBorder"
              >
                <span className="me-3">
                  <FormattedMessage defaultMessage="View more details" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </div>
          </div>
        </div>

        {images && images.length > 0 ? (
          <div className="col-lg-7 col-12 d-lg-block d-none h-90vh">
            <img
              style={{ height: "75%", objectFit: "cover" }}
              src={details.assets[0].url}
              alt={"property"}
              className="w-100 br8"
            />
            <div className="property-details-carousel mt-4">
              <AliceCarousel
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                items={images}
                mouseTracking
                touchMoveDefaultEvents={false}
                autoPlay={true}
                infinite={true}
                autoPlayInterval={1000}
              />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  const openDetails = details => {
    setGeneralModal({
      open: true,
      Component: () => renderMapDetails(details),
      modalClassName: "signInModal br16",
    })
  }

  const openPopover = (e, itemName) => {
    setAnchorEl(e.currentTarget)
    if (itemName === "sort") {
      setDropDownItems(dropItems)
    } else if (itemName === "filter") {
      setDropDownItems(dropItems)
    }
  }

  return (
    <Layout>
      <SEO title={"Map Finder"} />

      <div className="greyXLightBg min-h-98vh">
        <div className="p-3 p-lg-5">
          <div className="px-4 mb-5" style={{paddingTop: '50px'}}>
            <div className="signInBorder d-flex align-items-center justify-content-between">
              <span className="font-semi-bold black px36 mx-3">
                <FormattedMessage id="Map Finder" />
              </span>
              <div className="filterAndSortSelect">
                <button
                  className="d-flex align-items-center primary font-bold button-shadow px-2 br8 button-secondaryBorder mx-2 FilterSelect"
                  onClick={e => openPopover(e, "filter")}
                >
                  <span className="me-3 px20 FilterSelect">
                    <FormattedMessage defaultMessage="Filter" />
                  </span>
                  <ChevRight size="1.5rem" />
                </button>
                <button
                  className="d-flex align-items-center primary font-bold button-shadow px-2 br8 button-secondaryBorder mx-2 sortSelect"
                  onClick={e => openPopover(e, "sort")}
                >
                  <span className="me-3 px20 sortSelect">
                    <FormattedMessage defaultMessage="Sort" />
                  </span>
                  <ChevRight size="1.5rem" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-around m-0"
            style={{
              marginTop: "10px",
              width: "100%",
              paddingLeft: isMD || isMobile ? "0px" : "20px",
              paddingRight: isMD || isMobile ? "0px" : "30px",
            }}
          >
            <div className="col-xl-9 col-12">
              <div className="p-4 whiteBg br8" style={{ position: "relative" }}>
                {latLong && latLong.lat && latLong.long > 0 && (
                  <MapComponent
                    lat={latLong.lat}
                    long={latLong.long}
                    zoom={latLong && latLong.zoom ? latLong.zoom : 13}
                    mapTitle={"test"}
                    locations={properties}
                    onClickMarker={location => openDetails(location)}
                  />
                )}
                <div className="whiteBg primary font-bold px16 p-3 br8 custom-border-primary pointer d-flex align-items-center closeMapBtn">
                  <button
                    className="whiteBg d-flex justify-content-between w-100"
                    onClick={() => navigate("/")}
                  >
                    <ArrowLeft size="1.5rem" />
                    <span
                      className="me-3"
                      style={{ fontSize: "16px", fontWeight: "700" }}
                    >
                      <FormattedMessage defaultMessage="Close Map" />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-12 my-5 my-lg-0 px-2 overflow-scroll hideScrollBar h-70vh">
              <div className="whiteBg br8 px-3">
                {properties && properties.length > 0 && (
                  <div className="pt-2">
                    {properties.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          border:
                            item.id === latLong.id
                              ? "4px solid #3BBA9C"
                              : "4px solid transparent",
                          borderRadius: 8,
                          padding: 4,
                        }}
                        className="my-3 my-lg-0"
                      >
                        <div
                          className="d-flex align-items-top justify-content-between"
                          style={{ marginTop: "0px" }}
                        >
                          <div className="br8">
                            <img
                              style={{
                                width: "150px",
                                height: "130px",
                                objectFit: "cover",
                              }}
                              src={item.assets[0].url || defaultImg}
                              alt="property"
                              width="100%"
                              height="100%"
                              className="br8"
                            />
                          </div>
                          <div className="w-100" style={{ marginLeft: "8px" }}>
                            <div
                              className="font-bold"
                              style={{ fontSize: isMD ? "22px" : "26px" }}
                            >
                              For Sale
                            </div>
                            {/* <div className="darkGrey px14">{item.description && item.description.length > 10 ? `${item.description.substring(1, (item.description.length)/2)}....`:item.description}</div> */}
                            <div className="darkGrey px14 mapFinderItemDescription">
                              {item.description}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setLatLong({
                              id: item.id,
                              lat: item.lat,
                              long: item.long,
                              zoom: 18,
                            })
                            // openDetails(item);
                          }}
                          className="secondaryLightBg py-2 br8 mt-2 w-100 font-bold secondary d-flex align-items-center pointer justify-content-between"
                        >
                          <span className="px20 mx-2">SP {item.price}</span>
                          <span className="mx-2">
                            <ArrowRight size="1.5rem" />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeaderDropDownComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={dropDownItems}
      />
    </Layout>
  )
}
const mapStateToProps = ({
  data: { properties },
  settings: { lang, sort },
  shared: { loading },
}) => ({
  properties,
  loading,
  lang,
  sort,
})
export default connect(mapStateToProps, {
  clearPropertyFilters,
  setGeneralModal,
  openGeneralModal,
  getProperties,
  setLoading,
})(MapFinder)
