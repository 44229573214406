import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import AliceCarousel from "react-alice-carousel"
import Layout from "../components/layout"
import SEO from "../components/seo"
import serviceImg from "../assets/images/serviceImg.png"
import noProps from "../assets/images/no-props.png"
import _ from "lodash"
import ServiceComponent from "../components/serviceComponent"
import dataApi from "../_api/dataApi"
import { useCallback } from "react"
import Button from "../components/button"
import { useInfiniteLoading } from "../components/hooksComponents/useInfiniteLoading"

const AllServices = ({ setGeneralModal }) => {
  const [selectedService, setSelectedService] = useState({})
  const carouselItems = items => {
    return items
      ? items.map((item, index) => (
          <button
            key={index}
            className={`br16 greyLightBg greyBg-hover p-2
              ${selectedService.id === item.id ? "active-item" : ""}`}
            onClick={() => selectUnSelectService(item)}
          >
            <div className="font-bold px20">{item.title}</div>
          </button>
        ))
      : null
  }

  const [services, setServices] = useState(carouselItems(categories?.data))
  const [categories, setCategoryItems] = useState([])

  const selectUnSelectService = item => {
    setSelectedService(item)
    if (!_.isEmpty(selectedService) && selectedService.id === item.id)
      setSelectedService({})
  }
  const apiFilters = null
  const fetchServices = useCallback(
    (config = {}) => {
      if (!config?.page || config?.page === 1) {
        // setLoading(true)
      }
      return dataApi
        .getExperts({ ...config, filters: apiFilters })
        .then(res => {
          console.log(res)
          // setTotal(res?.total || 0)
          return Promise.resolve(res)
        })
        .catch(() => {
          // error()
          return Promise.resolve(null)
        })
        .finally(() => {
          // setLoading(false)
        })
    },
    [apiFilters]
  )
  const { items, loadMoreRef, setReload, hasNext, loadNext } =
    useInfiniteLoading({
      getItems: fetchServices,
      options: {
        loadingType: "infinite",
      },
    })

  useEffect(() => {
    setReload(true)
  }, [apiFilters])
  useEffect(async () => {
    const categories = await dataApi.getServiceCategory()
    // if (!_.isEmpty(categories)) {
    setServices(carouselItems(categories.data))
    // }
    if (!_.isEmpty(categories)) {
      setCategoryItems(categories.data)
    }
  }, [items, selectedService])

  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  }
  const renderService = item => {
    return items.filter(fil => fil.category.id === item.id).length > 0 ? (
      <>
        <div className="signInBorder my-3">
          <span className="font-semi-bold black px36 mx-3">{item.title}</span>
        </div>
        <div className="row pb-5">
          {items
            .filter(fil => fil.category.id === item.id)
            .map(service => {
              return (
                <ServiceComponent
                  key={item.id}
                  serviceData={service}
                  category={categories.find(i => i.id === service.category.id)}
                />
              )
            })}
        </div>
      </>
    ) : selectedService.id === item.id ? (
      <div className="w-100 h-100 mt-4 d-flex justify-content-center align-items-center flex-column">
        <img className="w-25 mb-3" src={noProps} alt="No Properties" />
        <div className="text-decorated mt-3 mb-4">
          <h2 className="px36 black font-600">
            <FormattedMessage defaultMessage="Opps No Results" />
          </h2>
          <p className="px20 black mb-0">
            <FormattedMessage defaultMessage="We did not find any services for this category" />
          </p>
        </div>
        <div className="d-block d-lg-none mt-2">
          <Button color="secondary" onClick={() => setSelectedService(item)}>
            <FormattedMessage defaultMessage="Try Again" />
          </Button>
        </div>
      </div>
    ) : null
  }

  return (
    <Layout>
      <SEO title={"All Services"} />
      <div className="linearBG">
        <div className="px-10rem">
          <div className="py-5">
            <div className="signInBorder">
              <span
                className="font-semi-bold black px55 mx-3 py-3"
                style={{
                  fontWeight: "900",
                }}
              >
                <FormattedMessage defaultMessage="Community experts" />
              </span>
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }} className="services-carousel px-10rem">
          {services && services.length > 0 ? (
            <AliceCarousel
              items={services}
              mouseTracking
              disableDotsControls={true}
              disableButtonsControls={true}
              touchMoveDefaultEvents={false}
              responsive={responsive}
              innerWidth={window.innerWidth}
              paddingLeft={25}
              paddingRight={25}
            />
          ) : null}
        </div>
        <div className="px-10rem">
          {_.isEmpty(selectedService) && (
            <div>
              <img
                alt={`services`}
                src={serviceImg}
                className="br16 shadow-lg my-5"
                width="100%"
                height="100%"
              />
            </div>
          )}
          <div className="p-0 p-xl-5">
            {categories && categories.length > 0
              ? categories.map(item =>
                  !_.isEmpty(selectedService)
                    ? selectedService.id === item.id && renderService(item)
                    : renderService(item)
                )
              : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ shared: { loading } }) => ({ loading })
export default connect(mapStateToProps, { })(AllServices)
