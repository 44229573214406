import React, { useContext, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CheckboxGroup from "../../propertyForm/checkboxGroup"
import FieldDesc from "../../propertyForm/fieldDesc"
import FormInput from "../../propertyForm/formInput"
import ListServiceContext from "../serviceContext"

export const showPhoneOptions = [
  { label: <FormattedMessage defaultMessage="Yes" />, value: true },
  { label: <FormattedMessage defaultMessage="No" />, value: false },
]

const FirstStep = () => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListServiceContext)

  const handleShowPhoneChange = value => {
    setFieldValue("show_phone", value)
  }

  useEffect(() => {
    setCurrentStepInputs([
      "first_name",
      "last_name",
      "phone",
      "show_phone",
      "email",
    ])
  }, [setCurrentStepInputs])

  return (
    <div className="firststepservice">
      <FieldDesc>
        <FormattedMessage defaultMessage="Personal Information" />
      </FieldDesc>
      <div className="">
        <FormInput
          label={<FormattedMessage defaultMessage="Title" />}
          name="title"
          placeholder={intl.formatMessage({
            defaultMessage: "Service title",
          })}
          outerClassName="titleserviceinput"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.title && errors.title}
        />
      </div>
      <div className="firstlastnameservice">
        <FormInput
          label={<FormattedMessage defaultMessage="First Name" />}
          name="first_name"
          placeholder={intl.formatMessage({
            defaultMessage: "First Name",
          })}
          outerClassName="d-flex flex-column w-100"
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.first_name && errors.first_name}
        />

        <FormInput
          label={<FormattedMessage defaultMessage="Last Name" />}
          name="last_name"
          placeholder={intl.formatMessage({
            defaultMessage: "Last Name",
          })}
          outerClassName="d-flex flex-column w-100"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.last_name && errors.last_name}
        />
      </div>
      <div className="phonenumberservice">
        <FormInput
          label={<FormattedMessage defaultMessage="Phone Number" />}
          name="phone"
          type="tel"
          placeholder={intl.formatMessage({
            defaultMessage: "Your Phone Number",
          })}
          outerClassName="phonenumberservice"
          value={values.phone}
          onChange={e => {
            if (/^[0-9 +]+$/.test(e.target.value) || e.target.value === "") {
              setFieldValue("phone", e.target.value)
            }
          }}
          onBlur={handleBlur}
          error={touched.phone && errors.phone}
        />
      </div>
      <div className="checkbox1stservice">
        <CheckboxGroup
          className="w-100 mx-2"
          disableMargin={true}
          labelClassname="checkboxlabelmargin"
          label={
            <FormattedMessage defaultMessage="Do you want to show your phone number to the website visitors?" />
          }
          outerClassName="checkbox1stservices"
          value={values.show_phone}
          options={showPhoneOptions}
          handleChange={handleShowPhoneChange}
        />
      </div>
      <div className="emailservice">
        <FormInput
          label={<FormattedMessage defaultMessage="Email Address" />}
          name="email"
          placeholder={intl.formatMessage({
            defaultMessage: "Your Email Address",
          })}
          outerClassName="emailservice"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
        />
      </div>
    </div>
  )
}

export default FirstStep
