import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../../assets/icons/arrowRight"
import { connect } from "react-redux"
import { setGeneralModal, setLoading } from "../../actions/settingsActions"
import ShareModal from "../../components/modals/shareModal"
import { myProfilePath } from "../../components/header"
import dataApi from "../../_api/dataApi"
import { error, success } from "../../utils"

const ProfileActionsButtons = ({ user, currentUser, ...props }) => {
  const [isFollowed, setIsFollowed] = useState(user && user.isFollowed)
  useEffect(() => {
    if (user) {
      setIsFollowed(user.isFollowed)
    }
  }, [user])
  const toggleFollow = () => {
    // setLoading(true)
    dataApi
      .toggleFollow(user?.id)
      .then(() => {
        setIsFollowed(isFollowed ? false : true)
        isFollowed
          ? success(
              <FormattedMessage
                defaultMessage="You unfollowed {name}"
                values={{ name: user.name }}
              />
            )
          : success(
              <FormattedMessage
                defaultMessage="You are following {name}"
                values={{ name: user.name }}
              />
            )
      })
      .catch(() => error())
      .finally(() => {
        // setLoading(false);
      })
  }

  const handleEditMyProfile = () => {
    navigate(`${myProfilePath}/edit`)
  }

  const isMyProfile = !user.id || currentUser?.id === user?.id

  const shareProfile = () => {
    const id = isMyProfile ? currentUser?.id : user?.id
    props.setGeneralModal({
      open: true,
      Component: ShareModal,
      modalClassName: "confirm-modal",
      componentProps: {
        pathname: `/app/profile/${id}`,
      },
    })
  }

  return (
    <div className="p-lg-4 p-3">
      {isMyProfile && (
        <>
          <div
            onClick={() => navigate(`${myProfilePath}/favorites`)}
            className="primary font-bold px16 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="My Favorite List" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
          <div
            onClick={() => navigate(`${myProfilePath}/followers`)}
            className="primary font-bold px17 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="My Follow List" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
          <div
            onClick={() => navigate(`${myProfilePath}/notifications`)}
            className="primary font-bold px17 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="My Notifications" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
          <div
            onClick={shareProfile}
            className="primary font-bold px17 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="Share My Profile" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
          <div
            className="white secondaryBg font-bold px17 p-3 br8 mb-4 pointer w-100 d-flex justify-content-between align-items-center"
            onClick={handleEditMyProfile}
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="Edit my profile" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
        </>
      )}
      {!isMyProfile && (
        <>
          <div
            onClick={shareProfile}
            className="primary font-bold px17 p-3 br8 custom-border-primary mb-4 pointer w-100 d-flex justify-content-between align-items-center"
          >
            <span className="me-3">
              <FormattedMessage defaultMessage="Share Profile" />
            </span>
            <ArrowRight size="1.5rem" />
          </div>
          <div
            className={`white ${
              isFollowed ? "primaryBg" : "secondaryBg"
            } font-bold px17 p-3 br8 mb-4 pointer w-100 d-flex justify-content-between align-items-center`}
            onClick={() => toggleFollow()}
          >
            {isFollowed ? (
              <span className="me-3">
                <FormattedMessage
                  defaultMessage="Unfollow {name}"
                  values={{ name: user?.name }}
                />
              </span>
            ) : (
              <span className="me-3">
                <FormattedMessage
                  defaultMessage="Follow {name}"
                  values={{ name: user?.name }}
                />
              </span>
            )}
            <ArrowRight size="1.5rem" />
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ currentUser: user })
export default connect(mapStateToProps, { setGeneralModal })(
  ProfileActionsButtons
)
