import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useInfiniteLoading } from "../../components/hooksComponents/useInfiniteLoading"
import defaultImage from "../../assets/images/noData.png"
import { useSelector } from "react-redux"
import ViewModeButtons from "../../components/viewModeButtons"
import PropertyGrid from "../../components/propertyGrid"

const ProfilePropertiesComponent = ({
  isMyProfile,
  isMobile,
  userId,
  fetchProperties,
  title,
  noDataPlaceholder,
  loading,
}) => {
  const [offerType, setOfferType] = useState("sale")
  const lang = useSelector(state => state?.settings?.lang)

  const { items, loadMoreRef, setReload, hasNext, loadNext } =
    useInfiniteLoading({
      getItems: (config = {}) =>
        isMyProfile
          ? fetchProperties(
              config,
              offerType + "&" + "filter=user.id||$eq||" + userId
            )
          : fetchProperties(config, offerType),
      options: {
        loadingType: "infinite",
      },
    })

  useEffect(() => {
    setReload(true)
  }, [offerType, lang])

  return (
    <div className="w-100 text-center">
      <div className="py-5">
        <div className="d-flex flex-md-row flex-column align-items-start align-items-md-center justify-content-between">
          {title && (
            <span className="text-decorated font-semi-bold black px36 mx-3">
              {title}
            </span>
          )}
          <div className="flex-1 d-flex justify-content-end">
            <div className="d-flex align-items-center justify-content-between">
              <div className="mx-lg-4 mt-5 mt-md-0 d-flex flex-lg-row flex-column greyLightBg shadow-sm br4 align-items-center justify-content-between flex-wrap flex-lg-nowrap">
                <button
                  style={{ height: "50px" }}
                  className={`my-profile-button primary font-bold m-1 br4 px-lg-5 px-3 py-2 button-shadow ${
                    offerType === "sale" ? "primaryBg" : "whiteBg"
                  }`}
                  onClick={() => setOfferType("sale")}
                >
                  <span
                    className={`px20 font-bold ${
                      offerType === "sale" ? "white" : "black"
                    }`}
                  >
                    <FormattedMessage defaultMessage="For Sale" />
                  </span>
                </button>
                <button
                  style={
                    isMobile
                      ? { height: "50px", width: "100%" }
                      : { height: "50px" }
                  }
                  className={`my-profile-button primary font-bold m-1 br4 px-lg-5 px-3 py-2 button-shadow ${
                    offerType === "rent" ? "primaryBg" : "whiteBg"
                  }`}
                  onClick={() => setOfferType("rent")}
                >
                  <span
                    className={`px20 font-bold ${
                      offerType === "rent" ? "white" : "black"
                    }`}
                  >
                    <FormattedMessage defaultMessage="For Rent" />
                  </span>
                </button>
              </div>
              <ViewModeButtons />
            </div>
          </div>
        </div>
      </div>
      {items?.length > 0 ? (
        <div className="row pb-5">
          <PropertyGrid forceVertical={isMobile} properties={items} />
          {hasNext && !loading && (
            <button
              ref={loadMoreRef}
              className="w-100 px24 font-bold p-2 secondaryBg white br8"
              onClick={() => loadNext()}
            >
              <FormattedMessage defaultMessage="Load More" />
            </button>
          )}
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center justify-content-center flex-column py-5">
            <img alt={"noData"} src={defaultImage} className="w-sm-100" />
            <div className="my-5">
              <div className="signInBorder">
                <div className="text-start font-semi-bold black px36 mx-3">
                  <FormattedMessage defaultMessage="Nothing to show here" />
                </div>
                <div
                  className="px20 text-start mx-3"
                  style={{ fontWeight: "400", color: "#353455" }}
                >
                 {!noDataPlaceholder && "User did not list any properties yet"}
                </div>
                {noDataPlaceholder && (
                  <div className="text-start black px20 mx-3">
                    {noDataPlaceholder}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="h-20vh" />
        </div>
      )}
    </div>
  )
}

export default ProfilePropertiesComponent
